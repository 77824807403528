import { FC, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { downloadFileFunc } from '../../../api/axiosCruds';
import { useApproveTaskMutation } from '../../../api/tenders';
import { UploadFileModal } from '../../Modals/UploadFileModal';
import { NewDocumentModal } from '../../Modals/NewDocumentModal';
import { RejectDocumentModal } from '../../Modals/RejectDocumentModal';

import deleteIcon from '../../../assets/DeleteIcon.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import SuppDocIcon from '../../../assets/SuppDocIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import uploadfile from '../../../assets/document.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import paint from '../../../assets/paint.svg';
import i from '../../../assets/i.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import edit from '../../../assets/editAdminIcon.svg';

import moment from 'moment';
import { AllTasksCompleted } from '../AllTasksCompleted';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setNewDocumentModal: React.Dispatch<React.SetStateAction<boolean>>;
    taskId: string | null | number;
    messageApi: any;
    newDocumentModal: boolean;
    documentModal: boolean;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteFileModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDocumentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DesignDocumentTask: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    taskId,
    setTaskId,
    messageApi,
    setTrigger,
    newDocumentModal,
    documentModal,
    setNewDocumentModal,
    setDocumentModal,
    setDeadlineModal,
    setIsDeleteFileModal
}) => {
    const [approveTask, { isLoading: isLoadingApprove }] = useApproveTaskMutation();

    const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
    const [isDeclineModal, setIsDeclineModal] = useState<boolean>(false);
    const [isShowDeadline, setIsShowDeadline] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isBanner =
        data?.phase_tasks.some((elem) => {
            if (elem.status === 'need_resend_tr') {
                return true;
            }
        }) || data?.phase_tasks.every((elem) => elem.status === 'completed');

    useEffect(() => {
        if (role === 'school_user') {
            setIsShowDeadline(false);
        } else {
            if (data.can_update_phase) {
                setIsShowDeadline(false);
            } else {
                setIsShowDeadline(true);
            }
        }
    }, [data, role]);

    const downloadFunc = (fileId: string, taskId: number, name: string) => {
        setIsLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                downloadPDF(response, name);
            })
            .catch(() => {
                messageApi.error('Downloading Error. Check your internet connection');
            })
            .finally(() => setIsLoading(false));
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {isShowDeadline && data?.tasks_deadline_at && (
                <div className={`tasks__info-wrap info-wrap `} style={{ margin: '0 0 15px 0' }}>
                    <div className="info-wrap__deadline">Task Deadline for School User</div>
                    <div className="info-wrap__status">{moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}</div>
                </div>
            )}

            {role === 'school_user' && data?.phase_tasks?.length > 0 && isBanner && <AllTasksCompleted />}
            {data?.phase_tasks.length > 0 ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'design_document') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                )}
                                            </>
                                        </div>
                                        {elem.comments.tender_representative && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">My Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>
                                        {elem.files.school_user?.[0]?.name && (
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                        {elem.comments.tender_representative && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">My Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {role !== 'super_admin' && (
                                            <img
                                                src={deleteIcon}
                                                className="task-complete__edit"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsDeleteFileModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }

                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__approved-active"
                                                    style={{ width: '220px' }}
                                                >
                                                    <img src={done} />
                                                    Approved by School User
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {elem.files.tender_representative[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        if (!isLoading) {
                                                            downloadFunc(
                                                                elem.files.tender_representative[0]?.uuid,
                                                                elem.id,
                                                                elem.files.tender_representative[0]?.name
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.declined &&
                                !elem.files.previous_version?.[0]?.name &&
                                elem.files.tender_representative
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info">
                                            {elem.files.school_user?.[0]?.name && (
                                                <>
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Previous File</div>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.school_user?.[0]?.name}
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                if (!isLoading) {
                                                                    downloadFunc(
                                                                        elem.files.school_user?.[0]?.uuid,
                                                                        elem.id,
                                                                        elem.files.school_user?.[0]?.name
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value school-user">
                                                <img src={messageGrey} />
                                                {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                            </div>
                                        </div>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative?.[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative?.[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative?.[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.declined &&
                                !elem.files.previous_version?.[0]?.name &&
                                !elem.files.tender_representative
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {role !== 'super_admin' && (
                                                    <Tooltip
                                                        title="To send back this activity, you need to download the document first"
                                                        overlayClassName={` ${
                                                            !elem.file_downloaded
                                                                ? 'custom-tooltip-disabled'
                                                                : 'custom-tooltip'
                                                        }`}
                                                    >
                                                        <div
                                                            className={`task-complete__decline ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setDocumentModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Send Back
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            {elem.files.school_user?.[0]?.name && (
                                                <>
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Received File</div>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.school_user?.[0]?.name}
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                if (!isLoading) {
                                                                    downloadFunc(
                                                                        elem.files.school_user?.[0]?.uuid,
                                                                        elem.id,
                                                                        elem.files.school_user?.[0]?.name
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {elem.files.school_user?.[0]?.name ? (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.school_user}
                                                </div>
                                            </div>
                                        )}
                                        {elem.files.tender_representative && (
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.tender_representative?.[0]?.name}
                                                        </div>
                                                    </div>
                                                    {elem.comments.files[
                                                        elem.files.tender_representative?.[0]?.uuid
                                                    ] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative?.[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {role !== 'super_admin' && (
                                                        <img
                                                            src={deleteIcon}
                                                            className="task-complete__edit"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsDeleteFileModal(true);
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.declined &&
                                elem.files.previous_version[0].name &&
                                elem.files.tender_representative
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {elem.files.previous_version?.[0]?.name && (
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Previous File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.previous_version?.[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.previous_version?.[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.previous_version?.[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value school-user">
                                                <img src={messageGrey} />
                                                {elem.comments.school_user}
                                            </div>
                                        </div>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative?.[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative?.[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative?.[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                elem.declined &&
                                elem.files.previous_version[0].name &&
                                !elem.files.tender_representative
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {role !== 'super_admin' && (
                                                    <Tooltip
                                                        title="To send back this activity, you need to download the document first"
                                                        overlayClassName={` ${
                                                            !elem.file_downloaded
                                                                ? 'custom-tooltip-disabled'
                                                                : 'custom-tooltip'
                                                        }`}
                                                    >
                                                        <div
                                                            className={`task-complete__decline ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setDocumentModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Send Back
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                        {elem.files.previous_version?.[0]?.name && (
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.previous_version?.[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.previous_version?.[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.previous_version?.[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value school-user">
                                                <img src={messageGrey} />
                                                {elem.comments.school_user}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.approved && !elem.declined) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}{' '}
                                            </div>

                                            <div className="task-complete__title-row">
                                                <Tooltip
                                                    title="To reject this activity, you need to download the document first"
                                                    overlayClassName={` ${
                                                        !elem.file_downloaded
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__decline ${
                                                            elem.file_downloaded ? '' : 'disable'
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.file_downloaded) {
                                                                setTaskId(elem.id);
                                                                setIsDeclineModal(true);
                                                            }
                                                        }}
                                                    >
                                                        Reject
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    title="To approve this activity, you need to download the document first"
                                                    overlayClassName={` ${
                                                        !elem.file_downloaded
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__approved ${
                                                            !elem.file_downloaded ? 'disabled-blue-button' : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.file_downloaded && !isLoadingApprove) {
                                                                approveTaskFunc(elem.id);
                                                            }
                                                        }}
                                                    >
                                                        Approve
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {elem.files.tender_representative[0]?.name}
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        if (!isLoading) {
                                                            downloadFunc(
                                                                elem.files.tender_representative[0]?.uuid,
                                                                elem.id,
                                                                elem.files.tender_representative[0]?.name
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>
                                        {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.tender_representative[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }

                            if (elem.status === 'pending_su' && elem.declined && elem?.files?.school_user?.[0]?.name) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete red
                                `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline-active"
                                                        style={{ margin: '0 0 0 16px' }}
                                                    >
                                                        <img src={stopImage} />
                                                        Rejected
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />

                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {
                                                                elem.comments.files[
                                                                    elem.files.tender_representative[0]?.uuid
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem?.files?.school_user?.[0]?.name}
                                                    </div>
                                                </div>

                                                <>
                                                    {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.school_user[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                <img
                                                    src={deleteIcon}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsDeleteFileModal(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'pending_su' &&
                                elem.declined &&
                                !elem?.files?.school_user?.[0]?.name &&
                                elem.comments.school_user
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete red
                                `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline-active"
                                                        style={{ margin: '0 0 0 16px' }}
                                                    >
                                                        <img src={stopImage} />
                                                        Rejected
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />

                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {
                                                                elem.comments.files[
                                                                    elem.files.tender_representative[0]?.uuid
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {elem.comments.school_user && (
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">My Comment</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.comments.school_user}
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            }

                            if (elem.status === 'pending_su' && elem.approved) {
                                return (
                                    <>
                                        {' '}
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete green
                                `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}{' '}
                                                </div>

                                                <div className="task-complete__title-row">
                                                    <div
                                                        className={`task-complete__decline ${
                                                            elem.file_downloaded ? '' : 'disable'
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.file_downloaded) {
                                                                setTaskId(elem.id);
                                                                setIsDeclineModal(true);
                                                            }
                                                        }}
                                                    >
                                                        Reject
                                                    </div>

                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <>
                                        {' '}
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete
                                `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}{' '}
                                                </div>

                                                <div className="task-complete__title-row">
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {
                                                                elem.comments.files[
                                                                    elem.files.tender_representative[0]?.uuid
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <>
                                        {' '}
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete 
                                `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />

                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>

                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            if (!isLoading) {
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id,
                                                                    elem.files.tender_representative[0]?.name
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {
                                                                elem.comments.files[
                                                                    elem.files.tender_representative[0]?.uuid
                                                                ]
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {!elem.files.school_user ? (
                                                <>
                                                    {elem.comments.school_user && (
                                                        <div className="task-complete__info-wrap">
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {elem.comments.school_user}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {elem?.files?.school_user?.[0]?.name && (
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem?.files?.school_user?.[0]?.name}
                                                        </div>
                                                    </div>

                                                    <>
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                            <>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">
                                                                        My Comment
                                                                    </div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {
                                                                            elem.comments.files[
                                                                                elem.files.school_user[0]?.uuid
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })
            ) : (
                <>
                    {role === 'tender_representative' && (
                        <>
                            <div className="add-first-document">
                                <div className="add-first-document__wrapper">
                                    <img src={i} className="add-first-document__image" />
                                    <div>
                                        At this stage, you need to specify the documents that the School User expects
                                        from you
                                    </div>
                                </div>
                                <div
                                    className="add-first-document__add"
                                    onClick={() => {
                                        setNewDocumentModal(true);
                                    }}
                                >
                                    <img src={uploadfile} className="add-first-document__image" />
                                    Add Document
                                </div>
                            </div>
                        </>
                    )}
                    {role === 'school_user' && (
                        <div className="noTasks">
                            <img src={paint} />
                            <div className="noTasks__title">Welcome to the Design Phase!</div>
                            <div className="noTasks__subtitle">
                                Watch this space - new tasks for you will be posted here as they become available.
                            </div>
                        </div>
                    )}
                </>
            )}

            {newDocumentModal && (
                <NewDocumentModal
                    openModal={newDocumentModal}
                    setOpenModal={setNewDocumentModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    type="create"
                />
            )}
            {documentModal && (
                <NewDocumentModal
                    openModal={documentModal}
                    setOpenModal={setDocumentModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    type="update"
                />
            )}
            {isUploadModal && (
                <UploadFileModal
                    openModal={isUploadModal}
                    setOpenModal={setIsUploadModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title="Supporting Document"
                    subtitle=""
                />
            )}

            {isDeclineModal && (
                <RejectDocumentModal
                    openModal={isDeclineModal}
                    setOpenModal={setIsDeclineModal}
                    data={data}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    title="Reject Document"
                />
            )}
        </>
    );
};
