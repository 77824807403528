import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useState } from 'react';
import { TaskDeadlineModal } from '../../../components/Modals/TaskDeadline';
import { useDeleteFileMutation, useUpdateTaskDeadlineMutation } from '../../../api/tenders';
import { UploadFileModal } from '../../Modals/UploadFileModal';
import { Button, Modal, Tooltip } from 'antd';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';

import igrey from '../../../assets/igrey.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import edit from '../../../assets/editAdminIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import TenderMandat from '../../../assets/TenderMandat.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';

import moment from 'moment';
import { AllTasksCompleted } from '../AllTasksCompleted';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const SupplierReportCard: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isClosingModal, setIsClosingModal] = useState(false);
    const [isSupplierReportCard, setIsSupplierReportCard] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(
        data?.tasks_deadline_at ? new Date(data?.tasks_deadline_at) : new Date()
    );
    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();
    const [prevFile, setPrevFile] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file: data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0].uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            .uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setIsClosingModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
                setLoading(false);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'supplier_report_card' || elem.action === 'evaluate_4_report_card') {
                        if (role !== 'school_user') {
                            if (
                                elem.status === 'pending_tr' &&
                                !elem.approved &&
                                !elem.files.tender_representative?.[0].uuid
                            ) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role !== 'super_admin' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsSupplierReportCard(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'pending_tr' &&
                                !elem.approved &&
                                elem.files.tender_representative?.[0].uuid
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={deleteIcon}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsDeleteFileModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'pending_su' &&
                                !elem.approved &&
                                elem.files.tender_representative?.[0].uuid
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>

                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                !elem.approved &&
                                elem.files.previous_version?.[0].uuid &&
                                !elem.files.tender_representative?.[0]?.uuid
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.previous_version?.[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.previous_version?.[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.previous_version?.[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setPrevFile(true);
                                                        setIsSupplierReportCard(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                !elem.approved &&
                                elem.files.previous_version?.[0].uuid &&
                                elem.files.tender_representative?.[0]?.uuid
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded File</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.tender_representative?.[0]?.name}
                                                    </div>
                                                </div>
                                                {elem.comments.files[elem.files.tender_representative?.[0]?.uuid] && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {
                                                                    elem.comments.files[
                                                                        elem.files.tender_representative?.[0]?.uuid
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setPrevFile(true);
                                                        setIsSupplierReportCard(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (
                                elem.status === 'pending_su' &&
                                !elem.approved &&
                                elem.files.tender_representative?.[0].uuid
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete ${
                                                data?.phase_tasks?.[0]?.file_downloaded ? 'green' : ''
                                            }`}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div style={{ fontWeight: '400' }}>
                                                                This document is updated at each evaluation gate and
                                                                outlines suppliers’ performance against your evaluation
                                                                criteria. <br />
                                                                <span style={{ fontWeight: '600' }}>Instruction</span>:
                                                                After reviewing the supplier report card, select the
                                                                supplier(s) you would like shortlist.
                                                            </div>
                                                        }
                                                    >
                                                        <img src={igrey} className="task-complete__igrey" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                if (loading) return;
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id
                                                                );
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_resend_tr' || elem.status === 'completed') {
                                return (
                                    <>
                                        {elem.status !== 'completed' && <AllTasksCompleted />}
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip title="This document is updated at each evaluation gate and outlines suppliers’ performance against your evaluation criteria. Instruction: After reviewing the supplier report card, select the supplier(s) you would like shortlist. ">
                                                        <img src={igrey} className="task-complete__igrey" />
                                                    </Tooltip>
                                                </div>
                                                {elem.status !== 'completed' && (
                                                    <div className="task-complete__title-row">
                                                        <div className="task__pending" key={elem.id}>
                                                            <img src={pendingIcon} />
                                                            Pending
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                if (loading) return;
                                                                downloadFunc(
                                                                    elem.files.tender_representative[0]?.uuid,
                                                                    elem.id
                                                                );
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}
            {isClosingModal && (
                <TaskDeadlineModal
                    title={'Tender closing date'}
                    buttonText="Confirm"
                    openModal={isClosingModal}
                    setOpenModal={setIsClosingModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                    data={data}
                />
            )}
            {isSupplierReportCard && (
                <UploadFileModal
                    openModal={isSupplierReportCard}
                    setOpenModal={setIsSupplierReportCard}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title={prevFile ? 'Update Supplier Report Card' : 'Supplier Report Card'}
                    subtitle={'Upload a file to import Supplier Report Card'}
                    specialFormats={['doc', 'pdf', 'zip']}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button
                                className="quit"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (isLoadingDeleteFile) return;
                                    deleteFileFunc();
                                }}
                            >
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
