import completedPhase from '../../assets/completedPhase.svg';
import i from '../../assets/igrey.svg';
import tenderDesign from '../../assets/tenderDesign.svg';
import tenderDefine from '../../assets/tenderDefine.svg';
import tenderLaunch from '../../assets/tenderLaunch.svg';
import Evaluate1 from '../../assets/Evaluate1+2.svg';
import AwardIcon from '../../assets/AwardIcon.svg';
import SupplierAppointment from '../../assets/SupplierAppointment.svg';
import PhaseCompleted1 from '../../assets/Phase Icons 01.svg';
import PhaseCompleted2 from '../../assets/Phase Icons 02.svg';
import PhaseCompleted4 from '../../assets/Phase Icons 04.svg';
import PhaseCompleted6 from '../../assets/Phase Icons 06.svg';
import PhaseCompleted7 from '../../assets/Phase Icons 07.svg';
import PhaseCompleted8 from '../../assets/Phase Icons 08.svg';
import leftImage from '../../assets/left-image.svg';
import arrowLeft from '../../assets/arrow-left.svg';
import arrow from '../../assets/arrow.svg';
import moment from 'moment';
import { Tooltip } from 'antd';
import { useState } from 'react';

export const SUTimelineOngoing = ({
    indexActivePhase,
    index,
    elem,
    activePhase,
    item,
    setOpenExplanationModal
}: any) => {
    const [isOpenTasks, setIsOpenTasks] = useState(false);

    const dispayTooltip = (days: number) => {
        return (
            <Tooltip
                title={
                    days > 0 ? (
                        <>
                            This phase was completed {days} days early. You won {days} extra days to complete the next
                            task
                        </>
                    ) : days < 0 ? (
                        <>
                            This phase was completed {Math.abs(days)} days late. To stay on track you must complete the
                            next task {Math.abs(days)} days sooner
                        </>
                    ) : (
                        ''
                    )
                }
            >
                <img src={i} />
            </Tooltip>
        );
    };

    return (
        <>
            <div className={`su__phase phase ${indexActivePhase === index ? 'active' : ''} `}>
                <div className="phase__title">
                    {elem.name}{' '}
                    {elem.name !== 'Supp. Appointment' && (
                        <img src={i} onClick={() => setOpenExplanationModal(elem.name)} />
                    )}
                </div>

                {elem.status === 'completed' && indexActivePhase !== index ? (
                    <div className="phase__img">
                        <img src={completedPhase} />
                    </div>
                ) : (
                    <div className="phase__img">
                        {elem.key === 'define' ? (
                            activePhase === 'define' ? (
                                <img src={PhaseCompleted8} />
                            ) : (
                                <img src={tenderDefine} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'design' ? (
                            activePhase === 'design' ? (
                                <img src={PhaseCompleted4} />
                            ) : (
                                <img src={tenderDesign} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'launch' ? (
                            activePhase === 'launch' ? (
                                <img src={PhaseCompleted7} />
                            ) : (
                                <img src={tenderLaunch} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'evaluate_1_2' ? (
                            activePhase === 'evaluate_1_2' ? (
                                <img src={PhaseCompleted6} />
                            ) : (
                                <img src={Evaluate1} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'evaluate_3' ? (
                            activePhase === 'evaluate_3' ? (
                                <img src={PhaseCompleted6} />
                            ) : (
                                <img src={Evaluate1} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'evaluate_4' ? (
                            activePhase === 'evaluate_4' ? (
                                <img src={PhaseCompleted6} />
                            ) : (
                                <img src={Evaluate1} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'award' ? (
                            activePhase === 'award' ? (
                                <img src={PhaseCompleted2} />
                            ) : (
                                <img src={AwardIcon} />
                            )
                        ) : (
                            ''
                        )}
                        {elem.key === 'supplier_appointment' ? (
                            activePhase === 'supplier_appointment' ? (
                                <img src={PhaseCompleted1} />
                            ) : (
                                <img src={SupplierAppointment} />
                            )
                        ) : (
                            ''
                        )}
                    </div>
                )}
                {elem.status === 'completed' && indexActivePhase - 1 > index ? (
                    <div className="phase__date">
                        <div className="phase__date">
                            <div className="su__name">Completed</div>
                        </div>
                        {elem.days > 0 && (
                            <div className="phase__early">{elem.days ? `${elem.days} days early` : ''}</div>
                        )}
                        {elem.days < 0 && (
                            <div className="phase__late">{elem.days ? `${Math.abs(elem.days)} days late` : ''}</div>
                        )}

                        {elem.days && (
                            <div className="phase__rounded">
                                {elem.days > 0 ? `+ ${elem.days}` : `${elem.days}`} days
                                {dispayTooltip(elem.days)}
                            </div>
                        )}
                    </div>
                ) : elem.status === 'completed' && indexActivePhase - 1 === index ? (
                    <>
                        <div className="phase__date">
                            <div className="su__name">Completed</div>
                        </div>
                        {elem.days > 0 && (
                            <div className="phase__early">{elem.days ? `${elem.days} days early` : ''}</div>
                        )}
                        {elem.days < 0 && (
                            <div className="phase__late">{elem.days ? `${Math.abs(elem.days)} days late` : ''}</div>
                        )}

                        {elem.days && (
                            <div className="phase__rounded">
                                {elem.days > 0 ? `+ ${elem.days}` : `${elem.days}`} days
                                {dispayTooltip(elem.days)}
                            </div>
                        )}
                    </>
                ) : indexActivePhase === index ? (
                    <>
                        {elem.deadline ? (
                            <>
                                <div className="phase__date">
                                    <div className="su__name">Deadline</div>
                                </div>
                                <div className="su__value ongoing">{moment(item.deadline).format('DD MMM YYYY')}</div>
                                {elem.days >= 0 ? (
                                    <div className="phase__rounded white">
                                        <img src={leftImage} />
                                        {elem.days} {elem.days === 1 ? 'day' : 'days'} left
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <>
                                <div className="phase__date">
                                    <div className="su__name">Completed</div>
                                </div>
                                {elem.days < 0 && (
                                    <div className="phase__late">
                                        {elem.days ? `${Math.abs(elem.days)} days late` : ''}
                                    </div>
                                )}
                                {elem.days > 0 && (
                                    <div className="phase__early">{elem.days ? `${elem.days} days early` : ''}</div>
                                )}
                                <div className="phase__late">
                                    {elem.days < 0 && (
                                        <div className="phase__rounded">
                                            {elem.days} days
                                            {dispayTooltip(elem.days)}
                                        </div>
                                    )}
                                    {elem.days > 0 && (
                                        <div className="phase__rounded">
                                            +{elem.days} days
                                            {dispayTooltip(elem.days)}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {elem.tasks.length ? (
                            <>
                                <div
                                    className="phase__tasks"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpenTasks((prev) => !prev);
                                    }}
                                >
                                    Tasks
                                    <img src={arrow} className={`phase__arrow ${isOpenTasks ? 'active' : ''}`} />
                                    <div className="phase__tasks-list">
                                        {isOpenTasks &&
                                            elem.tasks.map((task: any) => {
                                                return (
                                                    <div className={'phase__task task'}>
                                                        <div className="task__name">{task.name}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                ) : index === item.timeline?.length - 1 ? (
                    <>
                        {elem.days === 0 ? (
                            <>
                                <div className="phase__date">
                                    <div className="su__name">Estimated Completion</div>
                                </div>
                                <div className="su__value ongoing">
                                    {moment(elem?.estimated_end).format('DD MMM YYYY')}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="phase__date">
                                    <div className="su__name">Expected Date</div>
                                </div>
                                <div className="phase__last-wrap">
                                    <div className="su__value ongoing crossed-out">
                                        {moment(elem?.estimated_end).format('DD MMM')}
                                    </div>{' '}
                                    <img src={arrowLeft} className={'su__arrow'} />
                                    <div className="su__value ongoing red">
                                        {moment(elem?.actual_end).format('DD MMM YYYY')}
                                    </div>
                                </div>
                                <div className="phase__rounded">+ {elem.days} days</div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="phase__date">
                            <div className="su__name">Estimated Start</div>
                        </div>
                        <div className="su__value ongoing">{moment(elem?.estimated_start).format('DD MMM YYYY')}</div>
                    </>
                )}
            </div>{' '}
            {index < item.timeline?.length - 1 && <div className="phase__line"></div>}
        </>
    );
};
