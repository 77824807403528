import { FC, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Spin, message } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import { PhoneNumberInput } from '../Fields/PhoneNumberInput';
import { useCreateSchoolMutation, useEditSchoolMutation, useLazyGetSchoolQuery } from '../../api/schools';
import { useGetSchoolTypesQuery } from '../../api/common';
import { Select } from '../Fields/Select';
import { CustomErrorType } from '../../api/types/global';

import close from '../../assets/RoundedClose.svg';
import editIcon from '../../assets/editAdminIcon.svg';
import Phone from '../../assets/Phone.svg';
import RoundedWhite from '../../assets/RoundedWhite.svg';
import addressIcon from '../../assets/addressIcon.svg';
import schoolIcon from '../../assets/Pic.svg';
import heic2any from 'heic2any';

import * as Yup from 'yup';
import './Modals.scss';
import { FloatSelect } from '../Fields/FloatSelect';
import { useLazyGetUserDsQuery } from '../../api/users';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<{ type: string; open: boolean; id: number | null }>>;
    type: string;
    id: number | null;
    refetch: () => void;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name of School field is required'),
    type: Yup.object().required('Type field is required'),
    city: Yup.string().required('City field is required'),
    province: Yup.object().required('Province field is required'),
    address: Yup.string().required('Address field is required'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const ModalSchool: FC<ModalProps> = ({ openModal, setOpenModal, type, id, refetch }) => {
    const types = {};
    const { data: dataSchoolTypes } = useGetSchoolTypesQuery(types);

    const [createSchool, { error: errorCreateSchool }] = useCreateSchoolMutation();

    const [getSchoolTrigger, { data: dataSchool }] = useLazyGetSchoolQuery();

    const [editSchool, { error: errorEditSchool }] = useEditSchoolMutation();

    const [triggerSchoolUsers, { data: dataUsers, isSuccess: getUsersSuccess, isLoading: getUserssLoading }] =
        useLazyGetUserDsQuery(); // get school users

    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<any>();
    const [fileAvatar, setFileAvatar] = useState<any>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [changed, setChanged] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [fileErr, setFileErr] = useState('');
    const [searchUsers, setSearchUsers] = useState<string>('');

    useEffect(() => {
        if (type !== 'edit' || !id) return;
        const params = {
            filter: {
                search: searchUsers ? searchUsers : undefined,
                school_id: +id,
                role: ['school_user'],
                status: ['active']
            },
            per_page: -1,
            page: 1
        };
        triggerSchoolUsers(params);
    }, [type, searchUsers]);

    useEffect(() => {
        if (type !== 'create' && openModal) {
            getSchoolTrigger(id);
        }
    }, [type, openModal]);

    useEffect(() => {
        if (!dataSchool) return;
        form.setFieldsValue({
            name: dataSchool.data.name,
            city: dataSchool.data.city,
            type: dataSchool.data.type,
            phone_number: dataSchool.data.phone_number,
            province: dataSchool.data.province,
            address: dataSchool.data.address,
            head_user_id: dataSchool?.data?.head_user
        });
        setImageUrl(dataSchool.data.preview);
    }, [dataSchool, form]);

    const createSchoolHandler = async (res: any) => {
        setLoading(true);
        try {
            await createSchool(res).unwrap();
            await messageApi.success('The School has been created');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
            if ((errorCreateSchool as CustomErrorType)?.data?.message) {
                messageApi.error((errorCreateSchool as CustomErrorType)?.data?.message);
            } else {
                messageApi.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    };

    const editSchoolHandler = async (res: any) => {
        setLoading(true);
        try {
            await editSchool(res);
            await messageApi.success('School user has been successfully edited');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
            if (errorEditSchool) {
                messageApi.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = () => {
        form.validateFields().then((values: any) => {
            if (type === 'create') {
                const res = new FormData();
                res.append('name', values.name);
                res.append('type', values.type.key);
                res.append('city', values.city);
                res.append('province_id', values.province.id);
                res.append('address', values.address);
                res.append('phone_number', phoneNumber);
                fileAvatar && res.append('preview', fileAvatar);
                createSchoolHandler(res);
            }
            if (type === 'edit') {
                const res = new FormData();
                res.append('_method', 'PUT');
                res.append('name', values.name);
                res.append('type', values.type.key);
                res.append('city', values.city);
                res.append('province_id', values.province.id);
                res.append('address', values.address);
                res.append('phone_number', values.phone_number);
                if (values.head_user_id) {
                    res.append('head_user_id', values.head_user_id?.id);
                } else {
                    res.append('head_user_id', '');
                }
                if (!imageUrl) {
                    res.append('reset_preview', '1');
                } else {
                    if (fileAvatar) {
                        res.append('preview', fileAvatar);
                    }
                }
                const formData = {
                    id,
                    data: res
                };
                editSchoolHandler(formData);
            }
        });
    };

    const handleFileChange = async (event: any) => {
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];
        if (!file) return;
        const fileExtension = file.name.split('.').pop();

        let isValid = false;

        if (
            fileExtension === 'jpeg' ||
            fileExtension === 'jpg' ||
            fileExtension === 'png' ||
            fileExtension === 'heic'
        ) {
            isValid = true;
        }

        if (fileExtension === 'heic') {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                const HeicUrl = await convertHEICToJPG(file);
                setImageUrl(URL.createObjectURL(HeicUrl));
                setFileAvatar(file);
                setFileErr('');
            }
        } else {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                setChanged(true);
                setImageUrl(URL.createObjectURL(file));
                setFileAvatar(file);
                setFileErr('');
            }
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };

    const convertHEICToJPG = async (heicFile: File) => {
        const res: any = await heic2any({
            blob: heicFile,
            toType: 'image/jpeg',
            quality: 0.5
        });
        const newFile = new File([res], `${heicFile.name}`, {
            lastModified: Math.floor(new Date().getTime() / 1000),
            type: 'image/jpeg'
        });
        return newFile;
    };

    return (
        <>
            {contextHolder}
            <Modal
                destroyOnClose
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} modal`}
                centered
                open={openModal}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal({ open: false, type: '', id: null });
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched() || changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal({ open: false, type: '', id: null });
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button form="schoolForm" htmlType="submit" type="primary" loading={loading} block>
                            {type === 'create' ? 'Create' : type === 'edit' ? 'Save Changes' : ''}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loading} size="large">
                    <div className="modal">
                        <div className="modal__header">
                            <div className="modal__title">
                                {type === 'create' ? 'Create School' : type === 'edit' ? 'Edit' : ''}
                            </div>
                            {(type === 'view' || type === 'view_pending') && (
                                <div
                                    className="modal__edit"
                                    onClick={() => setOpenModal((prevState) => ({ ...prevState, type: 'edit' }))}
                                >
                                    <img src={editIcon} />
                                    <div>Edit</div>
                                </div>
                            )}
                            <div className="modal__avatar-wrap">
                                <div className="modal__addbtn">
                                    {imageUrl && (type === 'create' || type === 'edit') && (
                                        <img
                                            src={RoundedWhite}
                                            className="modal__delete-avatar-btn"
                                            onClick={() => {
                                                setChanged(true);
                                                setImageUrl('');
                                                setFileAvatar('');
                                            }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .heic"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />

                                    {imageUrl ? (
                                        <img src={imageUrl} className="upload-image" />
                                    ) : (
                                        <img src={schoolIcon} />
                                    )}

                                    <div className="modal__addtext" onClick={clickUpload}>
                                        {type === 'create' && !imageUrl
                                            ? 'Upload Picture'
                                            : (type === 'edit' && imageUrl) || (type === 'create' && imageUrl)
                                            ? 'Edit Picture'
                                            : type === 'edit' && !imageUrl
                                            ? 'Add Picture'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(type === 'create' || type === 'edit') && (
                            <div className="modal__main main">
                                {fileErr && <div className="main__file-error">{fileErr}</div>}

                                <div className="main__subtitle">
                                    Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                                </div>
                                <div className="main__block">
                                    <Form form={form} id="schoolForm" onFinish={handleFormSubmit} noValidate>
                                        <Form.Item
                                            className="input-wrapper"
                                            name="name"
                                            rules={yupSync('name', validationSchema, true)}
                                        >
                                            <FloatInput
                                                floatLabel="Name of School"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            className="input-wrapper"
                                            name="type"
                                            rules={yupSync('type', validationSchema, true)}
                                        >
                                            <Select
                                                setChanged={setChanged}
                                                floatLabel="Type"
                                                className="input"
                                                required={true}
                                                hasError={() => !!form.getFieldError('type').length}
                                                options={dataSchoolTypes?.data?.school_types}
                                            />
                                        </Form.Item>

                                        <div className="main__row">
                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="city"
                                                rules={yupSync('city', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="City"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="province"
                                                rules={yupSync('province', validationSchema, true)}
                                            >
                                                <Select
                                                    setChanged={setChanged}
                                                    value={dataSchool?.data?.province}
                                                    floatLabel="Province"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                    hasError={() => !!form.getFieldError(['province']).length}
                                                    options={dataSchoolTypes?.data?.provinces}
                                                />
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            className="input-wrapper"
                                            name="address"
                                            rules={yupSync('address', validationSchema, true)}
                                        >
                                            <FloatInput
                                                floatLabel="Address"
                                                className="input"
                                                maxLength={256}
                                                required={true}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className="input-wrapper"
                                            name="phone_number"
                                            rules={yupSync('phone_number', validationSchema, true)}
                                        >
                                            <PhoneNumberInput
                                                floatLabel="Phone Number"
                                                className="input"
                                                maxLength={256}
                                                placeholder={undefined}
                                                required={true}
                                                value={undefined}
                                                onChange={(e: any) => setPhoneNumber(e.target.value)}
                                                hasError={() => !!form.getFieldError('phone_number').length}
                                                onBlur={() => yupSync('phone_number', validationSchema, true)}
                                            />
                                        </Form.Item>
                                        {type === 'edit' && (
                                            <Form.Item className="input-wrapper" name="head_user_id">
                                                <FloatSelect
                                                    type={'user'}
                                                    search={searchUsers}
                                                    floatLabel="Head of School"
                                                    className="input-select"
                                                    required={false}
                                                    err={false}
                                                    setSearch={setSearchUsers}
                                                    options={dataUsers?.data}
                                                    onChange={() => {}}
                                                    value={''}
                                                    form={form}
                                                    disabled={false}
                                                    hasError={() => !!form.getFieldError(['school_user']).length}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        )}
                        {(type === 'view' || type === 'view_pending') && (
                            <div className="modal__main main">
                                <div className="main__fullName">{dataSchool?.data?.name}</div>
                                <div className="main__wrap-for-info school">
                                    <div className="main__info">
                                        <img src={Phone} />
                                        <span>{dataSchool?.data?.phone_number}</span>
                                    </div>
                                    <div className="main__info">
                                        <img src={addressIcon} />
                                        <span>{dataSchool?.data?.address}</span>
                                    </div>
                                </div>

                                <div className="main__wrap-for-info">
                                    <div className="main__job job">
                                        <div className="job__title">School Type</div>
                                        <div className="job__value">{dataSchool?.data?.type?.value}</div>
                                    </div>
                                </div>
                                <div className="main__wrap-for-info">
                                    <div className="main__job job">
                                        <div className="job__title">City</div>
                                        <div className="job__value">{dataSchool?.data?.city}</div>
                                    </div>
                                </div>
                                <div className="main__wrap-for-info last">
                                    <div className="main__job job ">
                                        <div className="job__title">Province</div>
                                        <div className="job__value">{dataSchool?.data?.province?.name}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Spin>
            </Modal>
            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal({ open: false, type: '', id: null });
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
