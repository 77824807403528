import { Button, Spin, message, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { ModalTender } from '../../components/Modals/ModalTender';
import { PlusCircleOutlined } from '@ant-design/icons';
import InputSearch from '../../components/Fields/InputSearch';
import { useLazyGetDashboardSUQuery, useLazyGetKanbanQuery } from '../../api/common';
import { PhaseI } from '../../api/types/common';
import { KanbanItem } from './KanbanItem';
import { useAppSelector } from '../../hooks';
import { KanbanItemSU } from './KanbanItemSU';
import { useLazyGetTenderQuery } from '../../api/tenders';
import useDebounceSearch from '../../hooks/useDebounceSearch';
import { CancelTenderModal } from '../../components/Modals/CancelTenderModal';
import NoDataOnDashboard from '../../assets/NoDataOnDashboard.svg';
import { KanbanItemOR } from './KanbanItemOR';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export const Dashboard = () => {
    const [isOpenCreateTenderModal, setIsOpenCreateTenderModal] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceSearch(search);
    const [getKanban, { data, isLoading }] = useLazyGetKanbanQuery();
    const [getDashboardSU, { data: dataSU, isLoading: isLoadingSU }] = useLazyGetDashboardSUQuery();
    const [isOpenEditTenderModal, setIsOpenEditTenderModal] = useState(false);
    const { role } = useAppSelector((state) => state.profileReducer);
    const [selectTender, setSelectTender] = useState<number | null>(null);
    const [getTender, { data: dataTender, isError: isErrorDataTender, error: errorDataTender }] =
        useLazyGetTenderQuery();
    const [trigger, setTrigger] = useState(false);
    const [openCancelTenderModal, setOpenCancelTenderModal] = useState(false);
    const [selectedTender, setSelectedTender] = useState<PhaseI | null>(null);
    const [openManage, setOpenManage] = useState<number | null>(null);
    const navigate = useNavigate();
    const [tab, setTab] = useState('ongoing');

    const items = [
        {
            key: 'ongoing',
            label: `Ongoing Tenders`
        },
        {
            key: 'upcoming',
            label: `Upcoming Tenders`
        }
    ];

    useEffect(() => {
        if (selectTender) {
            getTender(selectTender);
        }
    }, [selectTender]);

    useEffect(() => {
        if (!role) return;
        if (role === 'school_user') {
            getDashboardSU({ tab: tab });
        } else {
            getKanban({ search: debouncedSearch });
        }
    }, [trigger, debouncedSearch, role, tab]);

    return (
        <>
            <Spin spinning={isLoading} size="large">
                <div className="dashboard">
                    {contextHolder}
                    {role !== 'onboarding_representative' && role !== 'school_user' && (
                        <div className={`dashboard__top-row top-row ${role === 'school_user' ? 'school_user' : ''}`}>
                            <div className="top-row__item">
                                <div className="top-row__title">Total tenders</div>
                                <div className="top-row__value">{data?.data?.total_count}</div>
                            </div>
                            <div className="top-row__item">
                                <div className="top-row__title">
                                    {role === 'tender_representative' ? (
                                        'Value'
                                    ) : (
                                        <>
                                            Contract <br /> Value
                                        </>
                                    )}
                                </div>
                                <div className="top-row__value value">
                                    R{' '}
                                    {data?.data?.contract_value_format
                                        ?.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                    m
                                </div>
                            </div>
                            <div className="top-row__item">
                                <div className="top-row__title">Expense areas</div>
                                <div className="top-row__value">{data?.data?.expense_areas_count}</div>
                            </div>
                            <div className="top-row__item">
                                <div className="top-row__wrap">
                                    <div className="on-track color"></div>
                                    <div className="top-row__title">On-track</div>
                                </div>

                                <div className="top-row__value">{data?.data?.statuses?.on_track}</div>
                            </div>
                            <div className="top-row__item">
                                <div className="top-row__wrap">
                                    <div className="late color"></div>
                                    <div className="top-row__title">Late</div>
                                </div>
                                {role === 'school_user' ? (
                                    <div className="top-row__value">
                                        {data?.data?.statuses?.late && data?.data?.statuses?.follow_up
                                            ? data?.data?.statuses?.late + data?.data?.statuses?.follow_up
                                            : data?.data?.statuses?.late && !data?.data?.statuses?.follow_up
                                            ? data?.data?.statuses?.late
                                            : data?.data?.statuses?.follow_up}
                                        {}
                                    </div>
                                ) : (
                                    <div className="top-row__value">{data?.data?.statuses?.late}</div>
                                )}
                            </div>
                            <div className="top-row__item">
                                <div className="top-row__wrap">
                                    <div className="reminder color"></div>
                                    <div className="top-row__title">Reminder</div>
                                </div>

                                <div className="top-row__value">{data?.data?.statuses?.follow_up}</div>
                            </div>
                            {role === 'school_user' ? (
                                <></>
                            ) : (
                                <div className="top-row__item">
                                    <div className="top-row__wrap">
                                        <div className="on-hold color"></div>
                                        <div className="top-row__title">On Hold</div>
                                    </div>
                                    <div className="top-row__value">{data?.data?.statuses?.on_hold}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {role === 'onboarding_representative' && (
                        <>
                            <div className="dashboard__title">Supplier Registration Status</div>
                            <div className="dashboard__registration-status registration-status">
                                <div className="registration-status__item">
                                    <div>
                                        <div
                                            className="registration-status__title"
                                            onClick={() =>
                                                navigate(`/admin-panel?status=pending&docstatus=not_provided`)
                                            }
                                        >
                                            New Supplier Registrations
                                        </div>
                                        <div className="registration-status__subtitle">
                                            Pending Review and TPA send out
                                        </div>
                                    </div>
                                    <div className="registration-status__value">{data?.data?.new_registrations}</div>
                                </div>
                                <div className="registration-status__item">
                                    <div>
                                        <div
                                            className="registration-status__title"
                                            onClick={() => navigate(`/admin-panel?status=pending_tpa`)}
                                        >
                                            Completed Registrations
                                        </div>
                                        <div className="registration-status__subtitle">Pending TPA approval</div>
                                    </div>
                                    <div className="registration-status__value">
                                        {data?.data?.completed_registrations}
                                    </div>
                                </div>
                                <div className="registration-status__item">
                                    <div>
                                        <div
                                            className="registration-status__title"
                                            onClick={() =>
                                                navigate(`/admin-panel?status=unverified&docstatus=received`)
                                            }
                                        >
                                            Supplier Document Submission
                                        </div>
                                        <div className="registration-status__subtitle">Pending Verification </div>
                                    </div>
                                    <div className="registration-status__value">{data?.data?.document_submission}</div>
                                </div>
                            </div>
                        </>
                    )}
                    {role !== 'onboarding_representative' && role !== 'school_user' && (
                        <div className="dashboard__title">All Tenders</div>
                    )}
                    {role === 'onboarding_representative' && (
                        <div className="dashboard__title">Tender Registration Overview</div>
                    )}
                    {role && role !== 'school_user' && (
                        <div className="dashboard__filters">
                            <div className="dashboard__search">
                                <InputSearch handleChange={(value) => setSearch(value)} />
                            </div>
                            {role === 'super_admin' && (
                                <Button
                                    onClick={() => setIsOpenCreateTenderModal(true)}
                                    htmlType="submit"
                                    type="primary"
                                    // loading={loading}

                                    block
                                    icon={<PlusCircleOutlined />}
                                    className="dashboard__add"
                                >
                                    Add
                                </Button>
                            )}
                        </div>
                    )}

                    {role &&
                        role !== 'school_user' &&
                        role !== 'onboarding_representative' &&
                        !Array.isArray(data?.data?.kanban) && (
                            <>
                                {data?.data?.total_count ? (
                                    <div className="dashboard__kanban kanban">
                                        {role !== 'tender_representative' && (
                                            <div className="kanban__item">
                                                <div className="kanban__header">
                                                    Submit{' '}
                                                    <div className="kanban__number">
                                                        {' '}
                                                        {data?.data?.kanban?.submit?.length
                                                            ? data?.data?.kanban?.submit?.length
                                                            : 0}
                                                    </div>
                                                </div>
                                                {data?.data?.kanban?.submit?.map((elem: PhaseI) => {
                                                    return (
                                                        <KanbanItem
                                                            item={elem}
                                                            setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                            setSelectTender={setSelectTender}
                                                            setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                            setSelectedTender={setSelectedTender}
                                                            setOpenManage={setOpenManage}
                                                            openManage={openManage}
                                                            setTrigger={setTrigger}
                                                            messageApi={messageApi}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Define
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.define?.length
                                                        ? data?.data?.kanban?.define?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.define?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Design{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.design?.length
                                                        ? data?.data?.kanban?.design?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.design?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Launch{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.launch?.length
                                                        ? data?.data?.kanban?.launch?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.launch?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 1+2{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_1_2?.length
                                                        ? data?.data?.kanban?.evaluate_1_2?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_1_2?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 3{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_3?.length
                                                        ? data?.data?.kanban?.evaluate_3?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_3?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 4{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_4?.length
                                                        ? data?.data?.kanban?.evaluate_4?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_4?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                {' '}
                                                Award{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.award?.length
                                                        ? data?.data?.kanban?.award?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.award?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                        setTrigger={setTrigger}
                                                        messageApi={messageApi}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {isLoading ? (
                                            <></>
                                        ) : (
                                            <div className="noTasks">
                                                <img src={NoDataOnDashboard} />
                                                <div className="noTasks__title">No active tenders yet</div>
                                                <div className="noTasks__subtitle">
                                                    Сurrent tenders will be displayed here
                                                </div>
                                                {role && role === 'super_admin' && (
                                                    <Button
                                                        onClick={() => setIsOpenCreateTenderModal(true)}
                                                        htmlType="submit"
                                                        type="primary"
                                                        block
                                                        icon={<PlusCircleOutlined />}
                                                        className="dashboard__add nodata"
                                                        style={{ margin: '30px 0 0 0', width: '150px' }}
                                                    >
                                                        Add Tender
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    {role === 'school_user' && (
                        <>
                            <div className="dashboard__nav nav">
                                <div className="nav__title">Dashboard</div>
                                <Tabs defaultActiveKey="ongoing" items={items} activeKey={tab} onChange={setTab} />
                            </div>
                        </>
                    )}
                    {role && role === 'school_user' && (
                        <div className="dashboard__wrapper">
                            {dataSU?.data?.length ? (
                                dataSU?.data?.map((elem: PhaseI) => {
                                    return <KanbanItemSU item={elem} tab={tab} setTrigger={setTrigger} />;
                                })
                            ) : (
                                <>
                                    {isLoadingSU ? (
                                        <></>
                                    ) : (
                                        <div className="noTasks">
                                            <img src={NoDataOnDashboard} />
                                            <div className="noTasks__title">No active tenders yet</div>
                                            <div className="noTasks__subtitle">
                                                Сurrent tenders will be displayed here
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {role && role === 'onboarding_representative' && !Array.isArray(data?.data?.kanban) && (
                        <>
                            {data?.data?.kanban?.early_bird?.length ||
                            data?.data?.kanban?.live?.length ||
                            data?.data?.kanban?.ongoing?.length ? (
                                <div className="dashboard__kanban kanban">
                                    <div className="kanban__item OR">
                                        <div className="kanban__header">
                                            Early Bird
                                            <div className="kanban__number">
                                                {' '}
                                                {data?.data?.kanban?.early_bird?.length
                                                    ? data?.data?.kanban?.early_bird?.length
                                                    : 0}
                                            </div>
                                        </div>
                                        {data?.data?.kanban?.early_bird?.map((elem: PhaseI) => {
                                            return (
                                                <KanbanItemOR
                                                    item={elem}
                                                    setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                    setSelectTender={setSelectTender}
                                                    setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                    setSelectedTender={setSelectedTender}
                                                    setOpenManage={setOpenManage}
                                                    openManage={openManage}
                                                    type="early_bird"
                                                />
                                            );
                                        })}
                                    </div>

                                    <div className="kanban__item OR">
                                        <div className="kanban__header">
                                            Live
                                            <div className="kanban__number">
                                                {' '}
                                                {data?.data?.kanban?.live?.length
                                                    ? data?.data?.kanban?.live?.length
                                                    : 0}
                                            </div>
                                        </div>
                                        {data?.data?.kanban?.live?.map((elem: PhaseI) => {
                                            return (
                                                <KanbanItemOR
                                                    item={elem}
                                                    setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                    setSelectTender={setSelectTender}
                                                    setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                    setSelectedTender={setSelectedTender}
                                                    setOpenManage={setOpenManage}
                                                    openManage={openManage}
                                                    type="live"
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className="kanban__item OR">
                                        <div className="kanban__header">
                                            Ongoing
                                            <div className="kanban__number">
                                                {' '}
                                                {data?.data?.kanban?.ongoing?.length
                                                    ? data?.data?.kanban?.ongoing?.length
                                                    : 0}
                                            </div>
                                        </div>
                                        {data?.data?.kanban?.ongoing?.map((elem: PhaseI) => {
                                            return (
                                                <KanbanItemOR
                                                    item={elem}
                                                    setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                    setSelectTender={setSelectTender}
                                                    setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                    setSelectedTender={setSelectedTender}
                                                    setOpenManage={setOpenManage}
                                                    openManage={openManage}
                                                    type="ongoing"
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {isLoading ? (
                                        <></>
                                    ) : (
                                        <div className="noTasks">
                                            <img src={NoDataOnDashboard} />
                                            <div className="noTasks__title">No active tenders yet</div>
                                            <div className="noTasks__subtitle">
                                                Сurrent tenders will be displayed here
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Spin>
            {isOpenCreateTenderModal && (
                <ModalTender
                    type="create"
                    openModal={isOpenCreateTenderModal}
                    setOpenModal={setIsOpenCreateTenderModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                />
            )}
            {isOpenEditTenderModal && (
                <ModalTender
                    type="edit"
                    openModal={isOpenEditTenderModal}
                    setOpenModal={setIsOpenEditTenderModal}
                    messageApi={messageApi}
                    data={dataTender?.data}
                    setTrigger={setTrigger}
                />
            )}
            {openCancelTenderModal && (
                <CancelTenderModal
                    openModal={openCancelTenderModal}
                    setOpenModal={setOpenCancelTenderModal}
                    setTrigger={setTrigger}
                    selectedTender={selectedTender}
                />
            )}
        </>
    );
};
