import { useState, FC, useEffect } from 'react';
import { useSendTaskMutation, useUpdateTaskDeadlineMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import { SupplierReportCard } from './EvaluatePhaseComponent/SupplierReportCard';
import { SupplierList } from './EvaluatePhaseComponent/SupplierList';

import arrow from '../../assets/arrow-right-white.svg';
import RefreshIcon from '../../assets/Rotate, Refresh, Loading.svg';
import attention from '../../assets/attention.svg';
import edit from '../../assets/editAdminIcon.svg';

import moment from 'moment';
import './phases.scss';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { PitchAvailabilityForm } from './EvaluatePhaseComponent/PitchAvailabilityForm';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { AllTasksCompleted } from './AllTasksCompleted';
import { SupplierListNew } from './DesignPhaseComponents/SupplierListNew';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Evaluate12: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        if (data?.phase_tasks[0].action === 'supplier_report_card') {
            let formData = {};

            formData = {
                id: data?.id
            };
            try {
                await sendTask(formData).unwrap();
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' &&
                    (data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                        data?.phase_tasks?.[0]?.status === 'need_resend_tr') && (
                        <div className="tasks__header define">
                            <div className="count-wrapper">
                                Task Centre
                                <div className="count">{data?.phase_task_count}</div>
                            </div>
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        </div>
                    )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.action === 'supplier_report_card' &&
                            data?.phase_tasks?.[0]?.status === 'pending_su' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}

                <div className="tasks__list">
                    {role !== 'school_user' && data?.tasks_deadline_at && (
                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                            <div className="info-wrap__status">
                                {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        </div>
                    )}
                    {/* {
                        role === 'school_user' && data?.can_send_task
                        && <AllTasksCompleted />
                    } */}
                    <SupplierReportCard data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                    <SupplierListNew data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                    <PitchAvailabilityForm data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                </div>

                {role === 'tender_representative' &&
                    data?.phase_tasks?.[0]?.status !== 'need_resend_tr' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task &&
                                    data.phase_tasks[0]?.status !== 'pending_su' &&
                                    data.phase_tasks[1]?.status !== 'pending_su'
                                        ? !data?.suppliers?.every((elem) => elem.grade?.value)
                                            ? 'To send this task, you need to assign a score to each supplier first'
                                            : 'To send this task, you need complete all Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'pending_su'
                                        ? 'custom-tooltip-disabled'
                                        : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                {role === 'school_user' && data.phase_tasks.length > 0 && (
                    <div className="tasks__btn-wrap">
                        <div></div>

                        <Tooltip
                            title={
                                data?.suppliers.filter((elem) => elem.status === 'shortlisted')?.length !==
                                data?.phase_tasks.find((elem) => elem.action === 'supplier_pitch_availability_form')
                                    ?.slots?.length
                                    ? 'The number of slots cannot be less than the number of shortlisted suppliers. Please add additional slots to proceed'
                                    : 'To send this task, you need to complete all the Task Activities'
                            }
                            overlayClassName={` ${
                                !data?.can_send_task && data.phase_tasks?.[0]?.status !== 'need_resend_tr'
                                    ? 'custom-tooltip-disabled'
                                    : 'custom-tooltip'
                            }`}
                        >
                            <Button
                                className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                type="primary"
                                onClick={() => {
                                    if (!data?.can_send_task) {
                                        return;
                                    } else {
                                        if (!isLoadingSendTask) {
                                            sendTaskFunc();
                                        }
                                    }
                                }}
                            >
                                Submit <img src={arrow} />
                            </Button>
                        </Tooltip>
                    </div>
                )}
                {role === 'tender_representative' && data?.phase_tasks?.[0]?.status === 'need_resend_tr' && (
                    <div className="tasks__btn-wrap">
                        <div style={{ width: '47%' }}></div>

                        <div
                            className={`tasks__resend ${data.can_send_task ? '' : 'disable'}`}
                            onClick={() => {
                                if (data.can_send_task) {
                                    sendTaskFunc();
                                }
                            }}
                        >
                            <SpinnerIcon
                                fill={data.can_send_task ? '#001965' : '#b3bad1 '}
                                style={{ margin: '0 10px 0 0' }}
                            />
                            Resend All Suppliers
                        </div>

                        <Button
                            className={`tasks__next-btn ${data.can_update_phase ? '' : 'disabled'}`}
                            type="primary"
                            onClick={() => {
                                if (data.can_update_phase) {
                                    setConfirmModal(true);
                                }
                            }}
                        >
                            Next Phase <img src={arrow} />
                        </Button>
                    </div>
                )}
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                    data={data}
                />
            )}
        </>
    );
};
