import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useResendFormMutation, useSupplyAgreementMutation, useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { SupplierLinksI, tenderDataProps } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { error } from 'console';
import ErrorList from 'antd/es/form/ErrorList';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface FormDatatoSend {
    ids: { tender_id: number; supplier_id: string };
    data: { link: string };
}

interface SupplierData {
    company: { name: string };
    manager: { name: string; email: string; phone_number: string };
    name: string;
    email: string;
    incumbent: boolean;
    id: string;
    name_prefix: string;
    link: string;
    payload: {
        supply_agreement: string;
    };
}

export interface SuppliersErrorI {
    id: number;
    error: string;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    taskId: number | null;
    isResend: boolean;
}

export const NegotiatedSupplyAgreement: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    taskId,
    isResend
}) => {
    const [isDirty, setIsDirty] = useState(false);
    const [updateTask, { data: updatedData, isSuccess, isLoading, isError }] = useUpdateTaskMutation();
    const [
        resendForm,
        { data: resendData, isSuccess: isSuccessResend, isLoading: isLoadingResend, isError: isErrorResend }
    ] = useResendFormMutation();

    const task = data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement');

    const { handleKeyPress } = usePreventSpaceTyping();

    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    const [links, setLinks] = useState<SupplierLinksI[]>([]);
    const [errors, setErrors] = useState<SuppliersErrorI[]>([]);

    useEffect(() => {
        if (task) {
            let linksArray: SupplierLinksI[] = [];
            let errorsArray: SuppliersErrorI[] = [];
            if (isResend) {
                linksArray = task?.links?.sent_suppliers?.map((link) => link);
                errorsArray = task?.links?.sent_suppliers?.map((link) => ({
                    id: link.id,
                    error: ''
                }));
            } else {
                linksArray = task?.links?.suppliers?.map((link) => link);
                errorsArray = task?.links?.suppliers?.map((link) => ({
                    id: link.id,
                    error: ''
                }));
            }

            if (task.comments?.tender_representative_sent) {
                form.setFieldValue('comment', task.comments.tender_representative_sent);
            }
            setLinks(linksArray);
            setErrors(errorsArray);
            const comment = task.comments?.tender_representative;
            if (comment) {
                form.setFieldValue('comment', comment);
            }
        }
    }, [task]);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success(updatedData?.message);
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (isSuccessResend) {
            messageApi?.success(resendData?.message);
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
        if (isErrorResend) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessResend, isErrorResend]);

    const handleFormSubmit = () => {
        let isValid = true;
        const errorsArray: SuppliersErrorI[] = links.map((link) => {
            if (!link.link) {
                isValid = false;
                return { id: link.id, error: 'This field is required' };
            } else if (!/^https?:\/\//.test(link.link)) {
                isValid = false;
                return { id: link.id, error: 'Oops! Your link is not correct' };
            } else {
                return { id: link.id, error: '' };
            }
        });
        setErrors(errorsArray);
        form.validateFields()
            .then((values: any) => {
                if (!data?.id || !isValid) return;

                if (isResend) {
                    const formData = {
                        tender_id: data?.id,
                        data: {
                            task_id: taskId,
                            suppliers: links.map((elem) => ({ id: elem.id, link: elem.link })),
                            comment: values.comment
                        }
                    };
                    resendForm(formData);
                } else {
                    const formData = {
                        ids: {
                            tender_id: data?.id,
                            task_id: taskId
                        },
                        data: {
                            suppliers: links.map((elem) => ({ id: elem.id, link: elem.link })),
                            comment: values.comment
                        }
                    };
                    updateTask(formData);
                }
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>

                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading || isLoadingResend ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Negotiated Supply Agreement</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 15px 0' }}>
                            Enter links Negotiated Supply Agreement for{' '}
                            <span style={{ color: '#1d1d61' }}>
                                each suppliers ({links?.length} {links?.length === 1 ? 'link' : 'links'})
                                <span className="red">*</span>
                            </span>
                        </div>
                        <Form form={form} onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            {links?.map((link, index) => {
                                return (
                                    <div style={{ margin: '20px 0' }} key={link.id}>
                                        <div className="main__name">{link.name}</div>
                                        <FloatTextArea
                                            floatLabel={'Link to Negotiated Supply Agreement'}
                                            maxLength={1024}
                                            value={link.link}
                                            required={false}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e: any) => {
                                                setLinks((prev) =>
                                                    prev.map((item, i) =>
                                                        i === index ? { ...item, link: e.target.value } : item
                                                    )
                                                );
                                                setErrors((prev) =>
                                                    prev.map((item, i) =>
                                                        i === index
                                                            ? {
                                                                  ...item,
                                                                  error: !/^https?:\/\//.test(e.target.value)
                                                                      ? 'Oops! Your link is not correct'
                                                                      : ''
                                                              }
                                                            : item
                                                    )
                                                );
                                            }}
                                            hasError={() => !!errors.find((elem) => elem.id === link.id)?.error}
                                        />
                                        <div className="error-text">
                                            {errors.find((elem) => elem.id === link.id)?.error}
                                        </div>
                                    </div>
                                );
                            })}
                            <Form.Item name="comment">
                                <FloatTextArea
                                    floatLabel={'Comment'}
                                    maxLength={1024}
                                    required={false}
                                    hasError={() => false}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
