import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useDeclineTaskMutation, useSupplyAgreementMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import { yupSync } from '../../utils';
import i from '../../assets/i.svg';
import * as Yup from 'yup';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface FormDatatoSend {
    ids: { tender_id: number; supplier_id: string };
    data: { link: string };
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('This is a required field')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        })
});

export const ChangeRequestModal: FC<ModalProps> = ({ openModal, setOpenModal, messageApi, data, setTrigger }) => {
    const [isDirty, setIsDirty] = useState(false);

    const [declineTask, { data: declineData, isLoading, isSuccess, isError }] = useDeclineTaskMutation();

    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success(declineData?.message);
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccess, isError]);

    const handleFormSubmit = () => {
        if (isLoading) return;
        form.validateFields()
            .then((values: any) => {
                if (!data?.id) return;
                const formData = {
                    ids: {
                        tenderId: data?.id,
                        taskId: data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement')?.id
                    },
                    data: { comment: values.comment }
                };
                declineTask(formData).unwrap();
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>

                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '120px' }}>
                        <div className="modal__title">
                            Request a Change to Supply <br />
                            Agreement
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="note" style={{ margin: '0 0 15px 0' }}>
                            <img src={i} />
                            <div>
                                <b>Important Note:</b> Please ensure your change requests are complete, as we will
                                proceed with implementing them and finalising the agreement for signing. If anything in
                                your requests is unclear, we will reach out to clarify.
                            </div>
                        </div>
                        <div className="main__title">
                            Please insert your comments directly into the supply agreement for clarity, or add them
                            here.
                        </div>
                        <Form form={form} onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Comment'}
                                    maxLength={1024}
                                    required={true}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
