import { FC, useState, useEffect } from 'react';
import { Button, Divider, Form, Modal, Spin, Tooltip } from 'antd';
import { DatePickerInput } from '../Fields/DatePickerInput';
import { Loader } from '../Loader/Loader';
import i from '../../assets/igrey.svg';
import close from '../../assets/RoundedClose.svg';
import moment from 'moment';
import { useCalculateMutation, useLazyGetEstimationQuery, useSubmitEstimationMutation } from '../../api/common';
import { CustomErrorType } from '../../api/types/global';
import DatePicker from 'react-datepicker';
import { addMonths, isWithinInterval, parseISO, differenceInDays } from 'date-fns';
import { useAppSelector } from '../../hooks';
import dayjs from 'dayjs';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    taskId?: number | null | string;
    isCreateModal: boolean;
    tenderId: number;
}

interface ParamsI {
    id: string;
    data: {
        start_date: any;
        calculated_date?: any;
        end_date: any;
    };
}

export const TimelineModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    setTrigger,
    tenderId,
    isCreateModal
}) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [form] = Form.useForm();
    const user = useAppSelector((state) => state.profileReducer);

    const [getEstimation, { data, isLoading }] = useLazyGetEstimationQuery();
    const [getCalculate, { data: dataCalculated }] = useCalculateMutation();
    const [updateEstimations, { isSuccess, isError, error }] = useSubmitEstimationMutation();

    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [dates, setDates] = useState<any>({
        date_from: null,
        date_to: null
    });
    const [phasesArray, setPhasesArray] = useState<any>([]);

    const isTR = user?.role === 'tender_representative';

    const calculate = async (name: string, date: any) => {
        const params: ParamsI = {
            id: tenderId + '',
            data: {
                start_date:
                    name === 'date_from'
                        ? moment(date).format('YYYY-MM-DD')
                        : moment(dates.date_from).format('YYYY-MM-DD'),
                end_date:
                    name === 'date_to' ? moment(date).format('YYYY-MM-DD') : moment(dates.date_to).format('YYYY-MM-DD')
            }
        };

        const datesObj = { ...dates };
        datesObj[name] = date;

        try {
            const response = await getCalculate(params).unwrap();
            if (name === 'date_from') {
                datesObj.date_to = new Date(response?.data?.end_date);
                setDates(datesObj);
            } else {
                setDates(datesObj);
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
        }
    };

    useEffect(() => {
        if (data) {
            setDates({
                date_from: new Date(data?.data?.start_date),
                date_to: new Date(data?.data?.end_date)
            });
        }
    }, [data]);

    useEffect(() => {
        if (tenderId) {
            getEstimation(tenderId + '');
        }
    }, [tenderId]);

    useEffect(() => {
        if (isSuccess) {
            messageApi?.success('Great news! The changes to the tender have been saved successfully');
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
        if (isError) {
            if (error) {
                messageApi?.error((error as CustomErrorType)?.data?.message);
            } else {
                messageApi?.error('Something went wrong, please try again');
            }
        }
    }, [isSuccess, isError]);

    const handleFormSubmit = () => {
        let isValid = true;
        if (!dates.date_from || !dates.date_to) {
            isValid = false;
        }

        if (!isValid) return;
        const postData = {
            id: tenderId,
            data: {
                start_date: moment(dates.date_from).format('YYYY-MM-DD'),
                calculated_date: data?.data?.calculated_date,
                end_date: moment(dates.date_to).format('YYYY-MM-DD')
            }
        };
        updateEstimations(postData);
    };

    const isDayDisabled = (date: Date): boolean => {
        const isWeekend = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6;
        const isBeforeToday = (date: Date): boolean => date < new Date();

        const isThreeWorkingDaysAfterToday = (date: Date): boolean => {
            const today = new Date();
            const diffTime = date.getTime() - today.getTime();
            const diffDays = diffTime / (1000 * 60 * 60 * 24);

            if (diffDays > 0 && diffDays <= 4) {
                return !isWeekend(date);
            }
            return false;
        };

        const isAfterDateTo = (date: Date, dateTo: Date): boolean => date >= dateTo;

        if (
            isBeforeToday(date) ||
            isWeekend(date) ||
            isThreeWorkingDaysAfterToday(date) ||
            (dates.date_to && isAfterDateTo(date, dates.date_to))
        ) {
            return false;
        } else {
            return true;
        }
    };

    const isDayEndDisabled = (date: Date): boolean => {
        if (!data?.data?.calculated_date) {
            return false;
        }

        const isWeekend = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6;

        const isBeforeCalcDate = (date: Date, date_from: Date): boolean => date < date_from;

        const isAfterTwoMonthsFromDate = (date: Date, date_from: Date): boolean => {
            const twoMonthsLater = new Date(date_from);
            twoMonthsLater.setMonth(date_from?.getMonth() + 2);
            return date > twoMonthsLater;
        };

        const calcDate =
            moment(data?.data?.start_date).format('YYYY-DD-MM') === moment(dates.date_from).format('YYYY-DD-MM') &&
            moment(data?.data?.end_date).format('YYYY-DD-MM') === moment(dates?.date_to).format('YYYY-DD-MM')
                ? data?.data?.calculated_date
                : dataCalculated?.data?.calculated_date;

        return !(
            isBeforeCalcDate(date, new Date(calcDate)) ||
            (isAfterTwoMonthsFromDate(date, new Date(calcDate)) && !isTR) ||
            isWeekend(date)
        );
    };
    const [phases, setPhases] = useState<any>([]);

    useEffect(() => {
        if (!dates.date_from || !dates.date_to) return;

        const phasesArray =
            moment(data?.data?.start_date).format('MM-DD-YYYY') === moment(dates.date_from).format('MM-DD-YYYY') &&
            moment(data?.data?.end_date).format('MM-DD-YYYY') === moment(dates?.date_to).format('MM-DD-YYYY')
                ? data?.data
                : dataCalculated?.data;

        setPhasesArray(phasesArray);
        const phasesWithClassNames = phasesArray?.payload?.phases.map((phase: any, index: number) => {
            const startDate = new Date(phase.estimated_start);
            const endDate = new Date(phase.estimated_end);

            if (index !== 6) {
                endDate.setDate(endDate.getDate() - 1);
            }

            return {
                start: startDate,
                end: endDate,
                className: `phase-${index + 1}`
            };
        });

        setPhases(phasesWithClassNames);
    }, [dates.date_from, dates.date_to, dataCalculated?.data, data?.data]);

    const getDayClass = (date: Date) => {
        const resetTime = (d: Date) => new Date(d.setHours(0, 0, 0, 0));

        const phase = phases?.find((p: any) =>
            isWithinInterval(resetTime(date), { start: resetTime(p.start), end: resetTime(p.end) })
        );

        if (phase) {
            if (resetTime(date).getTime() === resetTime(phase.start).getTime()) {
                return `first_day ${phase.className}`;
            }
            if (resetTime(date).getTime() === resetTime(phase.end).getTime()) {
                return `last_day ${phase.className}`;
            }
            return phase.className;
        }

        return '';
    };

    return (
        <>
            <Modal
                className="modal-modal timeline"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
                        <div style={{ display: 'flex', justifyItems: 'flex-end', gap: '16px', width: '30%' }}>
                            <Button
                                block
                                key="back"
                                onClick={() => {
                                    if (form.isFieldsTouched()) {
                                        setIsConfirmModal(true);
                                    } else {
                                        setOpenModal(false);
                                    }
                                }}
                                className="cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                block
                                key="submit"
                                type="primary"
                                onClick={() => {
                                    if (dates.date_from && dates.date_to) {
                                        handleFormSubmit();
                                    }
                                }}
                                disabled={dates.date_from && dates.date_to ? false : true}
                            >
                                {false ? <Loader /> : 'Save'}
                            </Button>
                        </div>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title long">Tender Timeline</div>
                    </div>

                    <div className="modal__main main row" style={{ padding: '10px 35px 43px 35px' }}>
                        <div className="main__timelineModal">
                            <div className="main__terder-info">
                                <>
                                    {isLoading ? (
                                        <Spin />
                                    ) : (
                                        <Form form={form} onFinish={handleFormSubmit}>
                                            {isTR ? (
                                                <></>
                                            ) : (
                                                <div className="note" style={{ margin: '15px 0 0 0' }}>
                                                    <div>
                                                        <b>Important Note:</b> The tender completion date is an estimate
                                                        and depends on supplier timeliness, which is outside of our
                                                        control. It also does not account for any transition period that
                                                        may be required.
                                                    </div>
                                                </div>
                                            )}
                                            <div className="main__row">
                                                <Form.Item
                                                    className={`${
                                                        screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                    } ${isTR ? 'disable' : ''}`}
                                                >
                                                    <DatePickerInput
                                                        isImage={true}
                                                        onChange={(date: string) => {
                                                            if (isTR) {
                                                                return;
                                                            }
                                                            if (date && dates.date_to) {
                                                                calculate('date_from', date);
                                                            } else {
                                                                setDates((prev: any) => ({ ...prev, date_from: date }));
                                                            }
                                                        }}
                                                        value={dayjs(dates.date_from).format('YYYY-MM-DD')}
                                                        required={false}
                                                        placeholder=""
                                                        disabled={isTR ? true : false}
                                                        floatLabel="Start date"
                                                        filterDisabled={isDayDisabled}
                                                        allowClear={false}
                                                        dateFormat="YYYY-MM-DD"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className={`${
                                                        screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                    }`}
                                                >
                                                    <DatePickerInput
                                                        isImage={true}
                                                        onChange={(date: string) => {
                                                            if (date && dates.date_from) {
                                                                calculate('date_to', date);
                                                            } else {
                                                                setDates((prev: any) => ({ ...prev, date_to: date }));
                                                            }
                                                        }}
                                                        value={dates.date_to}
                                                        required={false}
                                                        placeholder=""
                                                        disabled={false}
                                                        floatLabel="End date"
                                                        filterDisabled={isDayEndDisabled}
                                                        allowClear={false}
                                                        dateFormat="YYYY-MM-DD"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    )}
                                    <Divider style={{ margin: '10px 0 20px 0' }} />
                                    <div className="main__title">Days to complete the tasks</div>
                                    <div className={`modal__list ${isTR ? 'TR' : ''}`}>
                                        {phasesArray?.payload?.phases?.map((elem: any) => {
                                            return (
                                                <div className="modal__phase phase">
                                                    <div className="phase__header">
                                                        <div className="phase__header-wrap">
                                                            {elem.name}
                                                            <img src={i} />
                                                        </div>
                                                        <div className="phase__header-wrap">{elem.days} Days</div>
                                                    </div>
                                                    <div className="phase__main">
                                                        {elem.actions.map((action: any) => {
                                                            return (
                                                                <div className="phase__task">
                                                                    <div className="phase__task-name">
                                                                        {action.name}
                                                                    </div>
                                                                    <div className="phase__task-days">
                                                                        {action.days} Days{' '}
                                                                        {action?.additional_days
                                                                            ? `(+${action.additional_days})`
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            </div>
                            <div className="row">
                                <div className="calendar-container">
                                    <DatePicker
                                        // selected={new Date(phases?.[0]?.start)}
                                        onChange={() => {}}
                                        inline
                                        monthsShown={3}
                                        dayClassName={getDayClass}
                                        disabled
                                        showDisabledMonthNavigation
                                        renderDayContents={(day, date: any) => {
                                            const phase = phases?.find((p: any) =>
                                                isWithinInterval(new Date(date.setHours(0, 0, 0, 0)), {
                                                    start: new Date(p.start),
                                                    end: new Date(p.end)
                                                })
                                            );

                                            if (!phase) return <div>{day}</div>;

                                            const startDate = new Date(phase.start);
                                            const endDate = new Date(phase.end);
                                            const daysInPhase = differenceInDays(endDate, startDate) + 1;

                                            return (
                                                <Tooltip
                                                    title={
                                                        <div className="day-styles">
                                                            <div className="day-styles__title">
                                                                {phase.className === 'phase-1' && 'Define Phase'}
                                                                {phase.className === 'phase-2' && 'Design Phase'}
                                                                {phase.className === 'phase-3' && 'Launch Phase'}
                                                                {phase.className === 'phase-4' &&
                                                                    'Evaluation Gate 1+2 Phase'}
                                                                {phase.className === 'phase-5' &&
                                                                    'Evaluation Gate 3 Phase'}
                                                                {phase.className === 'phase-6' &&
                                                                    'Evaluation Gate 4 Phase'}
                                                                {phase.className === 'phase-7' && 'Award Phase'}
                                                            </div>
                                                            <div className="day-styles__days">
                                                                {moment(startDate).format('DD MMM')} -{' '}
                                                                {moment(endDate).format('DD MMM YYYY')}{' '}
                                                                <div className="day-styles__diff">
                                                                    ({daysInPhase} days)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <div className="date-container">
                                                        <div className="styles-days">{day}</div>
                                                    </div>
                                                </Tooltip>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                                // refetch();
                            }}
                            // block
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Are you sure you want to abandon the meeting setup?</div>
            </Modal>
        </>
    );
};
