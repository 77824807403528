import { Button, Modal } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useSendTaskMutation } from '../../../api/tenders';
import { tenderDataProps } from '../../../pages/TenderViewPage/types';
import { Loader } from '../../Loader/Loader';
import HeadUpIcon from '../../../assets/HeadUpIcon.svg';
import '../../Modals/Modals.scss';

interface HeadsUpI {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    data: tenderDataProps;
    setTrigger: Dispatch<SetStateAction<boolean>>;
}

export const HeadUpModal: FC<HeadsUpI> = ({ openModal, setOpenModal, data, setTrigger }) => {
    const [sendTask, { isLoading: isLoadingSendTask, isSuccess: isSuccessSent }] = useSendTaskMutation();

    const sendTaskFunc = async () => {
        let formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (isSuccessSent) {
            setOpenModal(false);
        }
    }, [isSuccessSent]);

    return (
        <Modal
            className="confirmModal"
            centered
            open={openModal}
            closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
            width={600}
            footer={
                <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                    <Button
                        className={`tasks__next-btn`}
                        style={{ width: '100%', margin: '20px 0 0 0' }}
                        type="primary"
                        onClick={() => {
                            if (isLoadingSendTask) return;
                            sendTaskFunc();
                        }}
                    >
                        {isLoadingSendTask ? <Loader /> : 'Got it'}
                    </Button>
                </div>
            }
        >
            <div className="modal">
                <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                    <div className="main__title" style={{ margin: '0 0 6px 0', textAlign: 'center' }}>
                        <div>
                            <img src={HeadUpIcon} className="main__icon" />
                            <div className="main__title-no-head">Heads Up! We’ll Be in Touch Soon!</div>
                        </div>
                        As part of the Define Phase, we’ll be scheduling a meeting to ask follow-up questions and ensure
                        we are aligned on your tender requirements.
                        <br />
                        <br />
                        Watch out for a meeting request soon!
                    </div>
                </div>
            </div>
        </Modal>
    );
};
