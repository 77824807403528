import { FC, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import close from '../../assets/RoundedClose.svg';
import { useAddSuppliersToAwardPhaseMutation, useSendInvitesToSuppliersMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';
import Unverified from '../../assets/Unverified.svg';
import Verified from '../../assets/Verified.svg';
import done from '../../assets/Done, Check.svg';
import './Modals.scss';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data: tenderDataProps;
    selectedRowKeys: number[];
    allSuppliers: any;
    setOpenAddSupplierModal: React.Dispatch<React.SetStateAction<boolean>>;
    isAward: boolean;
}

export const InviteSuppliersModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    setTrigger,
    data,
    selectedRowKeys,
    allSuppliers,
    setOpenAddSupplierModal,
    isAward
}) => {
    const [sendInvites, { data: invitesData, isSuccess: isSuccessSendInvites, isLoading }] =
        useSendInvitesToSuppliersMutation();
    const [
        addSuppliersToAward,
        { data: invitesDatatoAward, isSuccess: isSuccessSendInvitesToAward, isLoading: isLoadingToAward }
    ] = useAddSuppliersToAwardPhaseMutation();

    useEffect(() => {
        if (isSuccessSendInvites) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            setOpenAddSupplierModal(false);
            message?.success(invitesData.message);
        }
    }, [isSuccessSendInvites]);

    useEffect(() => {
        if (isSuccessSendInvitesToAward) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            setOpenAddSupplierModal(false);
            message?.success(invitesDatatoAward.message);
        }
    }, [isSuccessSendInvitesToAward]);

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const handleFormSubmit = () => {
        const inviteData = {
            tender_id: data.id,
            data: {
                ids: selectedRowKeys
            }
        };
        if (isAward) {
            addSuppliersToAward(inviteData);
        } else {
            sendInvites(inviteData);
        }
    };

    return (
        <>
            <Modal
                destroyOnClose
                className={`modal`}
                centered
                open={openModal}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button block key="back" onClick={() => setOpenModal(false)} className="cancel">
                            Cancel
                        </Button>
                        <Button
                            form="schoolForm"
                            type="primary"
                            block
                            onClick={handleFormSubmit}
                            disabled={selectedRowKeys.length ? false : true}
                        >
                            {isLoading || isLoadingToAward ? <Loader /> : 'Invite'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header addsuppliermodal">
                        <div className="modal__title">{isAward ? 'Award' : 'Invite'} Suppliers</div>
                    </div>
                    <div className="modal__main main" style={{ padding: '15px 56px 43px 56px' }}>
                        <div className="main__supplierts-selected">
                            {isAward
                                ? 'Are you sure you want to award these suppliers?'
                                : 'Are you sure you want to invite these suppliers to the Tender?'}
                        </div>
                        <div className="main__supplier-list supplier-list">
                            {isAward
                                ? allSuppliers.map((elem: any) => {
                                      if (selectedRowKeys.includes(elem.id)) {
                                          return (
                                              <div className="supplier-list__item">
                                                  <div>
                                                      <div className="name">{elem.name}</div>
                                                      <div className="tr_name">{elem.trading_name}</div>
                                                  </div>
                                                  <div>
                                                      <div>
                                                          {elem.status === 'shortlisted' ? (
                                                              <div className="shortlisted">
                                                                  <img src={done} />
                                                                  Awarded{' '}
                                                              </div>
                                                          ) : (
                                                              '-'
                                                          )}
                                                      </div>
                                                  </div>
                                                  <div className="supplier_status">
                                                      <img src={statusImage(elem.company_status_name)} />
                                                      {elem.company_status_name}
                                                  </div>
                                              </div>
                                          );
                                      }
                                  })
                                : allSuppliers.map((elem: any) => {
                                      if (selectedRowKeys.includes(elem.id)) {
                                          return (
                                              <div className="supplier-list__item">
                                                  <div>
                                                      <div className="supplier-list__companyname">
                                                          {elem.company_name}
                                                      </div>
                                                      <div className="supplier-list__tradingname">
                                                          {elem.trading_name}
                                                      </div>
                                                  </div>
                                                  <div className="supplier_status">
                                                      <img src={statusImage(elem.status_name)} />
                                                      {elem.status_name}
                                                  </div>
                                              </div>
                                          );
                                      }
                                  })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
