import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { KanbanI } from './types/common';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery,
    endpoints: (builder) => ({
        getSchoolTypes: builder.query({
            query: () => ({
                url: '/common/reference-book',
                method: 'GET'
            })
        }),
        getKanban: builder.query<KanbanI, { search: string }>({
            query: (params) => ({
                url: '/tenders/kanban',
                method: 'GET',
                params
            })
        }),
        getDashboardSU: builder.query<any, { tab: string }>({
            query: (params) => ({
                url: '/tenders/dashboard',
                method: 'GET',
                params
            })
        }),
        getEstimation: builder.query<any, string>({
            query: (id) => ({
                url: `/tenders/${id}/estimation`,
                method: 'GET'
            })
        }),
        Calculate: builder.mutation({
            query: (params) => ({
                url: `/tenders/${params.id}/estimation/calculate`,
                method: 'POST',
                params: params.data
            })
        }),
        SubmitEstimation: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data?.id}/estimation`,
                    method: 'PUT',
                    body: data?.data
                };
            }
        })
    })
});

export const {
    useGetSchoolTypesQuery,
    useLazyGetKanbanQuery,
    useLazyGetEstimationQuery,
    useLazyGetDashboardSUQuery,
    useCalculateMutation,
    useSubmitEstimationMutation
} = commonApi;
