import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Divider, Form, Modal, Segmented, UploadFile } from 'antd';
import { Loader } from '../Loader/Loader';
import close from '../../assets/RoundedClose.svg';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { DatePickerInput } from '../Fields/DatePickerInput';
import moment from 'moment';

import { ContractsI, FormDataSupplier, tenderDataProps } from '../../pages/TenderViewPage/types';
import { FloatInput } from '../Fields/FloatInput';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';

import * as Yup from 'yup';
import { yupSync } from '../../utils';

const validationSchema = Yup.object().shape({
    annual_spend: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        })
});

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    TenderData: tenderDataProps;
    messageApi: any;
    supplirsLength: number;
}

export const CloseTenderModal: FC<ModalProps> = ({ openModal, setOpenModal, TenderData, messageApi, setTrigger }) => {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [data, setData] = useState<FormDataSupplier>({
        annual_spend: '',
        contracts: [{ value: '', duration: '', expiry_date: '', fee_amount: '', full_name: '' }],
        done_well: '',
        improvements: '',
        savings_achieved: 1,
        switched_suppliers: 1,
        calculations: []
    });

    const [errors, setErrors] = useState<number[]>([]);
    const [isDirty, setIsDirty] = useState(false);
    const [fileErr, setFileErr] = useState('');
    const [annualError, setAnnualError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!TenderData) return;
        const formData = TenderData?.phase_tasks?.[3]?.form_data;
        console.log('formData?.annual_spend', formData?.annual_spend);
        setData(() => ({
            ...formData,
            calculations: TenderData?.phase_tasks?.[3]?.files?.tender_representative?.length
                ? [{ name: TenderData?.phase_tasks?.[3]?.files?.tender_representative?.[0].name }]
                : [],
            contracts: [...formData.contracts],
            savings_achieved: formData.savings_achieved === 'no' ? 0 : 1,
            switched_suppliers: formData.switched_suppliers === 'no' ? 0 : 1,
            annual_spend:
                formData?.annual_spend === 0 && formData?.annual_spend !== null
                    ? formData?.annual_spend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
        }));
    }, [TenderData]);

    const scrollToElementById = (elementId: string) => {
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    const handleFormSubmit = async () => {
        let isValid = true;
        //check contracts
        const contractsWithError: number[] = [];
        data.contracts?.forEach((elem: ContractsI, index: number) => {
            if (!elem.value || !elem.duration || !elem.expiry_date || !elem.fee_amount || !elem.full_name?.trim()) {
                isValid = false;
                contractsWithError.push(index);
            }
        });
        setErrors(contractsWithError);

        //check annual_spend
        if (!data.annual_spend) {
            isValid = false;
            setAnnualError(true);
            scrollToElementById('annual_spend');
        }

        if (fileErr) {
            isValid = false;
        }

        if (isValid) {
            setIsLoading(true);
            const abortController = createAbortController();
            const formData = new FormData();
            if (data?.calculations[0]?.originFileObj) {
                const file: File = data.calculations[0].originFileObj;
                formData.append('file', file);
            }
            if (!data?.calculations.length) {
                formData.append('remove_file', 'yes');
            }
            if (data.done_well) {
                formData.append('done_well', data.done_well);
            }
            if (data.improvements) {
                formData.append('improvements', data.improvements);
            }
            const res = data.savings_achieved;
            const res1 = data.switched_suppliers;
            formData.append('annual_spend', data.annual_spend.toString().split(',').join(''));
            formData.append('savings_achieved', res === 1 ? 'yes' : 'no');
            formData.append('switched_suppliers', res1 === 1 ? 'yes' : 'no');

            const method: string = 'PUT';
            formData.append('_method', method);

            let count = 0;
            data.contracts.forEach((item: ContractsI) => {
                formData.append(`contracts[${count}][value]`, item.value.split(',').join(''));
                formData.append(`contracts[${count}][duration]`, item.duration.split(',').join(''));
                formData.append(`contracts[${count}][expiry_date]`, moment(item.expiry_date).format('YYYY-MM-DD'));
                formData.append(`contracts[${count}][fee_amount]`, item.fee_amount.split(',').join(''));
                formData.append(`contracts[${count}][full_name]`, item.full_name.trim());
                count = count + 1;
            });

            abortController.abort();

            const dataToPost = {
                ids: {
                    tender_id: TenderData.id,
                    task_id: TenderData.phase_tasks?.[3].id
                },
                data: formData
            };

            const newAbortController = createAbortController();
            const signal = newAbortController.signal;

            try {
                await UpdateTaskFile(dataToPost, signal);
                await setTrigger((prev: boolean) => !prev);
                setOpenModal(false);
                await messageApi?.success('The task has been updated successfully');
            } catch {
                setFileErr('Upload failed. Check your internet connection');
            } finally {
                setIsLoading(false);
            }
        } else {
        }
    };

    const handleInputChange = (index: number) => {
        setIsDirty(true);

        const indexToRemove = errors.indexOf(index);
        const newErrors = [...errors];
        if (indexToRemove !== -1) {
            newErrors.splice(indexToRemove, 1);
            setErrors(newErrors);
        }
    };

    return (
        <>
            <Modal
                className="modal-pitch"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isLoading) return;
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={() => {
                                if (isLoading) return;
                                handleFormSubmit();
                            }}
                        >
                            {isLoading ? <Loader /> : 'Confirm'}
                        </Button>
                    </div>
                }
            >
                <div className="modal pitch">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Close Tender form</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__readonlyinfo">
                            <div className="main__item-info item-info">
                                <div className="item-info__title">Tender</div>
                                <div className="item-info__value">{TenderData?.name}</div>
                            </div>
                            <div className="main__item-info item-info">
                                <div className="item-info__title">School</div>
                                <div className="item-info__value">
                                    <div>{TenderData?.school?.name}</div>
                                    <div className="item-info__city">({TenderData?.school?.address})</div>
                                </div>
                            </div>
                            <div className="main__item-info item-info">
                                <div className="item-info__title">Expense Area</div>
                                <div className="item-info__value">{TenderData?.expense_area?.name}</div>
                            </div>
                            <div className="main__item-info item-info">
                                <div className="item-info__title">Contracts Awarded</div>
                                <div className="item-info__value">
                                    {TenderData?.phase_tasks?.[3]?.form_data?.contracts?.length}
                                </div>
                            </div>
                        </div>

                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                        </div>
                        <div className="main__question-block question-block">
                            <div className="question-block__title">Did the School switch suppliers?</div>
                            <Segmented
                                options={['No', 'Yes']}
                                value={data.switched_suppliers === 1 ? 'Yes' : 'No'}
                                onChange={(value: any) => {
                                    setData((prev: FormDataSupplier) => ({
                                        ...prev,
                                        switched_suppliers: value === 'Yes' ? 1 : 0
                                    }));
                                    setIsDirty(true);
                                }}
                            />
                        </div>
                        <Divider />
                        <div className="main__question-block question-block">
                            <div className="question-block__title">Did the school make a saving?</div>
                            <Segmented
                                options={['No', 'Yes']}
                                value={data.savings_achieved === 1 ? 'Yes' : 'No'}
                                onChange={(value: any) => {
                                    setData((prev: FormDataSupplier) => ({
                                        ...prev,
                                        savings_achieved: value === 'Yes' ? 1 : 0
                                    }));
                                    setIsDirty(true);
                                }}
                            />
                        </div>
                        <Divider />

                        <div className="main__title" id="annual_spend">
                            What was the annual saving or cost increase achieved through this tender process? Please
                            attach calculations.
                        </div>
                        <Form.Item
                            className="input-wrapper"
                            name="annual_spend"
                            rules={yupSync('annual_spend', validationSchema, true)}
                        >
                            <FloatInput
                                name="annual_spend"
                                floatLabel={'Annual saving or cost increase'}
                                className="input"
                                maxLength={11}
                                required={false}
                                type="contractValue"
                                value={data.annual_spend}
                                setContractValue={(value: string) => {
                                    setData((prev: FormDataSupplier) => ({ ...prev, annual_spend: value }));
                                    setAnnualError(false);
                                }}
                                contractValue={data.annual_spend + ''}
                                setChanged={setIsDirty}
                                error={annualError}
                            />
                            {annualError && <div className="error-text">This is a required field</div>}
                        </Form.Item>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            Upload a file to import Calculations
                        </div>

                        <Form.Item name="file">
                            <FilePicker
                                disabled={isLoading ? true : false}
                                fileList={data.calculations}
                                onChange={(file: UploadFile[]) =>
                                    setData((prev: FormDataSupplier) => ({ ...prev, calculations: file }))
                                }
                                error={fileErr}
                                setError={setFileErr}
                                specialFormats={['pdf', 'doc', 'docx', 'xls', 'xlsx']}
                            />
                        </Form.Item>

                        <Divider style={{ margin: '37px 0 24px 0' }} />

                        <div className="main__title" style={{ margin: '0 0 16px 0' }}>
                            What went well during this tender project?
                        </div>
                        <FloatTextArea
                            floatLabel="Comment"
                            maxLength={512}
                            hasError={() => false}
                            value={data.done_well}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setData((prev: FormDataSupplier) => ({ ...prev, done_well: e.target.value }));
                                setIsDirty(true);
                            }}
                        />
                        <Divider style={{ margin: '24px 0' }} />
                        <div className="main__title" style={{ margin: '0 0 16px 0' }}>
                            What could be done better for the next time?
                        </div>
                        <FloatTextArea
                            floatLabel="Comment"
                            maxLength={512}
                            hasError={() => false}
                            value={data.improvements}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setData((prev: FormDataSupplier) => ({ ...prev, improvements: e.target.value }));
                                setIsDirty(true);
                            }}
                        />
                        <Divider style={{ margin: '24px 0' }} />

                        {data?.contracts?.map((elem: ContractsI, index: number) => {
                            return (
                                <>
                                    <div className={`slot ${errors.includes(index) ? 'error' : ''}`}>
                                        <div className="slot__title">Contract {index + 1}</div>
                                        <Form className="slot__form">
                                            <div className="slot__row">
                                                <Form.Item className="input-wrapper pitch-time">
                                                    <FloatInput
                                                        floatLabel={'Total Contract Value'}
                                                        className="input"
                                                        maxLength={11}
                                                        required={true}
                                                        type="contractValue"
                                                        value={elem.value}
                                                        setContractValue={(prop: string) => {
                                                            let element = { ...elem };
                                                            element.value = prop;
                                                            const copy = [...data.contracts];
                                                            copy[index] = element;

                                                            setData((prev: FormDataSupplier) => ({
                                                                ...prev,
                                                                contracts: copy
                                                            }));
                                                            handleInputChange(index);
                                                        }}
                                                        contractValue={elem.value}
                                                        setChanged={setIsDirty}
                                                    />
                                                </Form.Item>
                                                <Form.Item className="input-wrapper pitch-time">
                                                    <FloatInput
                                                        floatLabel={'Duration (Years)'}
                                                        className="input"
                                                        maxLength={2}
                                                        required={true}
                                                        value={elem.duration}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            let element = { ...elem };
                                                            element.duration = e.target.value;
                                                            const copy = [...data.contracts];
                                                            copy[index] = element;

                                                            setData((prev: FormDataSupplier) => ({
                                                                ...prev,
                                                                contracts: copy
                                                            }));
                                                            handleInputChange(index);
                                                        }}
                                                        setChanged={setIsDirty}
                                                    />{' '}
                                                </Form.Item>
                                            </div>
                                            <div className="slot__row">
                                                <Form.Item className="input-wrapper pitch-time">
                                                    <DatePickerInput
                                                        isImage={false}
                                                        onChange={(date: string) => {
                                                            let element = { ...elem };
                                                            element.expiry_date = date;
                                                            const copy = [...data.contracts];
                                                            copy[index] = element;

                                                            setData((prev: FormDataSupplier) => ({
                                                                ...prev,
                                                                contracts: copy
                                                            }));
                                                            handleInputChange(index);
                                                        }}
                                                        value={elem.expiry_date}
                                                        required={true}
                                                        placeholder=""
                                                        disabled={false}
                                                        floatLabel="Contract Expiry Date"
                                                        filterDisabled={false}
                                                    />
                                                </Form.Item>
                                                <Form.Item className="input-wrapper pitch-time">
                                                    <FloatInput
                                                        floatLabel={'Set-up fee amount'}
                                                        className="input"
                                                        maxLength={11}
                                                        required={true}
                                                        type="contractValue"
                                                        value={elem.fee_amount}
                                                        setContractValue={(prop: string) => {
                                                            let element = { ...elem };
                                                            element.fee_amount = prop;
                                                            const copy = [...data.contracts];
                                                            copy[index] = element;

                                                            setData((prev: FormDataSupplier) => ({
                                                                ...prev,
                                                                contracts: copy
                                                            }));
                                                            handleInputChange(index);
                                                        }}
                                                        contractValue={elem.fee_amount}
                                                        setChanged={setIsDirty}
                                                    />{' '}
                                                </Form.Item>
                                            </div>
                                            <Form.Item className="input-wrapper">
                                                <FloatInput
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        let element = { ...elem };
                                                        element.full_name = e.target.value;
                                                        const copy = [...data.contracts];
                                                        copy[index] = element;

                                                        setData((prev: FormDataSupplier) => ({
                                                            ...prev,
                                                            contracts: copy
                                                        }));
                                                        handleInputChange(index);
                                                    }}
                                                    value={elem.full_name}
                                                    floatLabel="Full Supplier Name"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    {errors.includes(index) && (
                                        <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                            These are required fields
                                        </div>
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
