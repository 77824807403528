export const CalendarSvg = ({ fill = '#001965', className }: { fill: string; className: any }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M6.24887 2.49805V4.99909"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.7528 2.49805V4.99909"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="2.49805"
            y="3.74805"
            width="15.0062"
            height="13.7557"
            rx="3"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.24805 13.3348H10.8333"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.6473 13.439C13.7049 13.439 13.7515 13.3924 13.7515 13.3348C13.7515 13.2773 13.7049 13.2306 13.6473 13.2306C13.5898 13.2306 13.5431 13.2773 13.5431 13.3348C13.5431 13.3924 13.5898 13.439 13.6473 13.439"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.752 10.0008H9.0625"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.35239 9.89661C6.29483 9.89661 6.24818 9.94327 6.24818 10.0008C6.24818 10.0584 6.29483 10.105 6.35239 10.105C6.40994 10.105 6.4566 10.0584 6.4566 10.0008C6.4566 9.94327 6.40994 9.89661 6.35239 9.89661"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
