import { FC, useState } from 'react';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { TaskDeadlineModal } from '../../../components/Modals/TaskDeadline';
import { useUpdateTaskDeadlineMutation } from '../../../api/tenders';

import RegistrationClosingDateIcon from '../../../assets/RegistrationClosingDate.svg';
import Rocket from '../../../assets/Rocket.svg';
import plus from '../../../assets/Plus, Add.svg';
import edit from '../../../assets/editAdminIcon.svg';
import calendar from '../../../assets/calendar-chedule.svg';

import moment from 'moment';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const RegistrationClosingDate: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    messageApi,
    setTrigger
}) => {
    const [isClosingModal, setIsClosingModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(
        data?.tasks_deadline_at ? new Date(data?.tasks_deadline_at) : new Date()
    );
    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setIsClosingModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'launch_registration_closing_date') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !data.tasks_deadline_at) {
                                return (
                                    <>
                                        <div key={3} className={`tasks__task task `}>
                                            <div className="task__title">
                                                <div className="task-complete__title-row">
                                                    <img src={RegistrationClosingDateIcon} />
                                                    {elem.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setIsClosingModal(true);
                                                }}
                                            />
                                        </div>
                                    </>
                                );
                            }
                            if (
                                (elem.status === 'pending_tr' ||
                                    elem.status === 'completed' ||
                                    elem.status === 'need_approve_tr') &&
                                !elem.approved &&
                                data.tasks_deadline_at
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={RegistrationClosingDateIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Date</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">
                                                    <img src={calendar} />
                                                    {moment(data.tasks_deadline_at).format('YYYY-MM-DD')}
                                                </div>
                                            </div>

                                            <img
                                                src={edit}
                                                className="task-complete__edit"
                                                onClick={() => {
                                                    setIsClosingModal(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}
            {isClosingModal && (
                <TaskDeadlineModal
                    title={'Registration Closing Date'}
                    buttonText="Confirm"
                    openModal={isClosingModal}
                    setOpenModal={setIsClosingModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                    data={data}
                />
            )}
        </>
    );
};
