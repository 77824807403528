import { ContractsI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useState } from 'react';
import { useApproveTaskMutation, useDeclineTaskMutation, useResetActionMutation } from '../../../api/tenders';
import { Button, Modal } from 'antd';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';

import stopImage from '../../../assets/stopImage.svg';
import edit from '../../../assets/editAdminIcon.svg';
import Money from '../../../assets/Money, Coins.svg';
import Payments from '../../../assets/Payments, Finance.svg';
import Interface from '../../../assets/Interface, Essential.svg';
import plus from '../../../assets/Plus, Add.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import CloseTender from '../../../assets/CloseTender.svg';
import done from '../../../assets/Done, Check.svg';
import { CloseTenderModal } from '../../Modals/CloseTenderModal';
import moment from 'moment';
import { RejectCloseTenderForm } from '../../Modals/RejectCloseTenderForm';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const CloseTenderForm: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isModal, setIsModal] = useState(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [resetAction, { isLoading: isLoadingResetAction }] = useResetActionMutation();
    const [loading, setLoading] = useState(false);
    const [approveTask, { isLoading: isLoadingApprove }] = useApproveTaskMutation();
    const [declineTask, { isLoading }] = useDeclineTaskMutation();
    const [rejectCloseTender, setRejectCloseTender] = useState(false);

    const deleteFileFunc = async () => {
        try {
            const formData: any = {
                tenderId: data.id,
                taskId: taskId,
                data: { action: 'decline' }
            };

            await resetAction(formData).unwrap();

            await setIsDeleteFileModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
                setLoading(false);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'award_close_tender_form') {
                        if (role === 'tender_representative') {
                            if (elem.status === 'pending_tr' && !elem.form_data.contracts?.[0]?.value) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <img
                                            src={plus}
                                            className="task__plus"
                                            onClick={() => {
                                                setTaskId(elem.id);
                                                setIsModal(true);
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'pending_tr' ||
                                    elem.status === 'pending_sa' ||
                                    elem.status === 'need_resend_tr') &&
                                elem.form_data.contracts?.[0]?.value
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            elem.status === 'pending_tr' || elem.status === 'need_resend_tr'
                                                ? 'green'
                                                : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                {elem.status === 'pending_sa' && (
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                {elem.files?.tender_representative?.[0]?.name && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Calculations</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files?.tender_representative[0].name}
                                                                </div>
                                                                <div
                                                                    className={`task-complete__download-btn `}
                                                                    onClick={() => {
                                                                        downloadFunc(
                                                                            elem.files?.tender_representative[0].uuid,
                                                                            elem.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={downloadIcon}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    Download
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {elem.status !== 'pending_sa' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setIsModal(true);
                                                    }}
                                                />
                                            )}
                                            {elem.comments?.declined && (
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Comment</div>
                                                        <div className="task-complete__value">
                                                            {elem.comments?.declined}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved by SA
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Calculations</div>
                                                </div>
                                                {elem.files?.tender_representative?.[0]?.name && (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <div>
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files?.tender_representative[0].name}
                                                            </div>
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files?.tender_representative[0].uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'super_admin') {
                            if (elem.status === 'pending_sa' && !elem.declined && !elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        if (isLoading) return;
                                                        setRejectCloseTender(true);
                                                    }}
                                                >
                                                    Reject
                                                </div>
                                                <div
                                                    className={`task-complete__approved`}
                                                    onClick={() => {
                                                        if (isLoadingApprove) return;
                                                        approveTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Approve
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Calculations</div>
                                                </div>
                                                {elem.files?.tender_representative?.[0].name && (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <div>
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files?.tender_representative[0].name}
                                                            </div>
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files?.tender_representative[0].uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                    <div className="task-complete__name">Contracts</div>
                                                </div>
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item name">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_sa' && elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete red`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__decline-active"
                                                    style={{ margin: '0 20px 0 0' }}
                                                >
                                                    <img src={stopImage} />
                                                    Rejected
                                                </div>
                                                <div
                                                    className={`task-complete__approved`}
                                                    onClick={() => {
                                                        if (isLoadingApprove) return;
                                                        approveTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Approve
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                {elem.files?.tender_representative?.[0]?.name && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Calculations</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files?.tender_representative[0].name}
                                                                </div>
                                                                <div
                                                                    className={`task-complete__download-btn `}
                                                                    onClick={() => {
                                                                        downloadFunc(
                                                                            elem.files?.tender_representative[0].uuid,
                                                                            elem.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={downloadIcon}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    Download
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments?.declined && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">My Comment</div>
                                                            <div className="task-complete__value">
                                                                {elem.comments.declined}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if ((elem.status === 'pending_sa' || elem.status === 'completed') && elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {elem.status !== 'completed' && (
                                                    <div
                                                        className="task-complete__decline"
                                                        onClick={() => {
                                                            if (isLoading) return;
                                                            setRejectCloseTender(true);
                                                        }}
                                                    >
                                                        Reject
                                                    </div>
                                                )}
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                {elem.files?.tender_representative?.[0]?.name && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Calculations</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files?.tender_representative[0].name}
                                                                </div>
                                                                <div
                                                                    className={`task-complete__download-btn `}
                                                                    onClick={() => {
                                                                        downloadFunc(
                                                                            elem.files?.tender_representative[0].uuid,
                                                                            elem.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={downloadIcon}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    Download
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CloseTender} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name closeform">
                                                    Annual saving or cost increase
                                                </div>
                                                <div className="task-complete__name closeform">Savings Achieved</div>
                                                <div className="task-complete__name closeform">Switched Suppliers</div>
                                                <div className="task-complete__name closeform">Contracts Awarded</div>
                                                <div className="task-complete__name closeform"></div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value closeform">
                                                    <img src={Money} /> R{' '}
                                                    {elem.form_data.annual_spend
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Payments} />
                                                    {elem.form_data.savings_achieved + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    <img src={Interface} />
                                                    {elem.form_data.switched_suppliers + '' === 'yes' ? 'Yes' : 'No'}
                                                </div>
                                                <div className="task-complete__value closeform">
                                                    {elem.form_data.contracts.length}
                                                </div>
                                            </div>
                                            <div
                                                className="task-complete__info-wrap"
                                                style={{ border: 'none', padding: '0' }}
                                            >
                                                {elem.files?.tender_representative?.[0]?.name && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Calculations</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files?.tender_representative[0].name}
                                                                </div>
                                                                <div
                                                                    className={`task-complete__download-btn `}
                                                                    onClick={() => {
                                                                        downloadFunc(
                                                                            elem.files?.tender_representative[0].uuid,
                                                                            elem.id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={downloadIcon}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    Download
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {elem.form_data.done_well ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What went well during this tender project?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.done_well}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {elem.form_data.improvements ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">
                                                            What could be done better for the next time?
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.form_data.improvements}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__contracts">
                                                {elem.form_data.contracts.map((item: ContractsI) => {
                                                    return (
                                                        <div className="task-complete__contract contract">
                                                            <div className="contract__item contract-name">
                                                                <div className="contract__title"></div>
                                                                <div className="contract__value contract-name">
                                                                    {item.full_name}
                                                                </div>
                                                            </div>
                                                            <div className="contract__wrapper">
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Value</div>
                                                                    <div className="contract__value">
                                                                        <img src={Money} /> R{' '}
                                                                        {item.value
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Duration</div>
                                                                    <div className="contract__value">
                                                                        {item.duration} Years
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">Expiry Date</div>
                                                                    <div className="contract__value">
                                                                        {moment(item.expiry_date).format('DD MMM YYYY')}
                                                                    </div>
                                                                </div>
                                                                <div className="contract__item">
                                                                    <div className="contract__title">
                                                                        Set-up fee payable
                                                                    </div>
                                                                    <div className="contract__value">
                                                                        {' '}
                                                                        <img src={Money} /> R{' '}
                                                                        {item.fee_amount
                                                                            .toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments?.declined && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">My Comment</div>
                                                            <div className="task-complete__value">
                                                                {elem.comments.declined}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}

            {isModal && (
                <CloseTenderModal
                    openModal={isModal}
                    setOpenModal={setIsModal}
                    TenderData={data}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    supplirsLength={2}
                />
            )}

            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingResetAction ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
            {rejectCloseTender && (
                <RejectCloseTenderForm
                    openModal={rejectCloseTender}
                    setOpenModal={setRejectCloseTender}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
        </>
    );
};
