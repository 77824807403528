import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, SetStateAction, useEffect, useState } from 'react';
import { Divider, Tooltip } from 'antd';
import edit from '../../../assets/editAdminIcon.svg';
import LetterIcon from '../../../assets/LetterIcon.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import igrey from '../../../assets/igrey.svg';
import Pitch from '../../../assets/Pitch.svg';
import { PitchForm } from '../../Modals/PitchForm';
import { ClockIcon } from '../../Icons/ClockIcon';
import { PeopleIcon } from '../../Icons/PeopleIcon';
import moment from 'moment';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';
import { ResendFormModal } from '../../Modals/ResendFormModal';
import { Message } from '../../Icons/Message';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

interface TimeObject {
    date: string;
    time_from: string;
    time_to: string;
}

interface GroupedData {
    date: string;
    dayOfWeek: string;
    week: number;
    time: string[];
}

export const PitchAvailabilityForm: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    messageApi,
    setTrigger
}) => {
    const [isPitchModal, setIsPitchModal] = useState(false);
    const [slots, setSlots] = useState<GroupedData[]>([]);
    const [openResendTask, setOpenResendTask] = useState(false);

    function convertToDesiredDateFormat(dateString: string): string {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(dateString);
        const dayOfWeekIndex = date.getDay();
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${months[monthIndex]} ${year}`;
    }

    function getWeekNumber(date: Date): number {
        const startOfWeek = new Date(date.getFullYear(), 0, 1);
        const diff = (date.getTime() - startOfWeek.getTime()) / (1000 * 60 * 60 * 24);
        return Math.ceil((diff + startOfWeek.getDay() + 1) / 7);
    }

    function groupByDate(array: TimeObject[]): GroupedData[] {
        const grouped: { [key: string]: { time: string[] } } = {};

        array.forEach((item: TimeObject) => {
            const { date, time_from, time_to } = item;

            if (!grouped[date]) {
                grouped[date] = { time: [] };
            }

            const timeRange = `${time_from}-${time_to}`;
            grouped[date].time.push(timeRange);
        });

        const result: GroupedData[] = Object.keys(grouped).map((key: string) => {
            const date = new Date(key);
            return {
                date: convertToDesiredDateFormat(key),
                dayOfWeek: date.toLocaleDateString('en-US', { weekday: 'short' }),
                week: getWeekNumber(date),
                time: grouped[key].time
            };
        });

        // Сортировка по дате в порядке возрастания
        result.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA.getTime() - dateB.getTime();
        });

        return result;
    }

    useEffect(() => {
        if (data?.phase_tasks?.[2]?.arrival_point) {
            const groupedData: GroupedData[] = groupByDate(data?.phase_tasks?.[2]?.slots);
            setSlots(groupedData);
        }
    }, [data?.phase_tasks?.[2]?.arrival_point, data]);

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'supplier_pitch_availability_form') {
                        if (role !== 'school_user') {
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={Pitch} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div
                                                    className={`tasks__resend ${data.can_send_task ? '' : 'disable'}`}
                                                    onClick={() => {
                                                        if (data.can_send_task) {
                                                            setOpenResendTask(true);
                                                        }
                                                    }}
                                                >
                                                    <SpinnerIcon
                                                        fill={data.can_send_task ? '#001965' : '#b3bad1 '}
                                                        style={{ margin: '0 10px 0 0' }}
                                                    />
                                                    Resend Form
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '170px', margin: '0 0 10px 0' }}
                                                >
                                                    Free slot for Campus Tour
                                                </div>
                                            </div>
                                            <div className="slot-item">
                                                <div className="slot-item__week">
                                                    {moment(elem.campus_tour.date).format('ddd')}
                                                </div>
                                                <div className="slot-item__date">
                                                    {moment(elem.campus_tour.date).format('DD MMMM YYYY')}
                                                </div>
                                                <div className="slot-item__time">
                                                    {elem.campus_tour.time_from}-{elem.campus_tour.time_to}
                                                </div>
                                            </div>
                                            <Divider style={{ margin: '16px 0' }} />
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '125px' }}>
                                                    Duration
                                                </div>
                                                <div className="task-complete__name">Representatives</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ width: '125px' }}>
                                                    <ClockIcon fill="#001965" />
                                                    {elem.duration}min
                                                </div>
                                                <div className="task-complete__value">
                                                    <PeopleIcon fill="#001965" style={{ margin: '0 5px 0 0' }} />
                                                    {elem.representatives_count}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '200px', margin: '0 0 10px 0' }}
                                                >
                                                    Free slots for supplier pitches
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__slot-list">
                                                    {slots?.map((elem: GroupedData) => {
                                                        return (
                                                            <div className="slot-item">
                                                                <div className="slot-item__week">{elem.dayOfWeek}</div>
                                                                <div className="slot-item__date">{elem.date}</div>
                                                                {elem.time.map((time: string) => {
                                                                    return (
                                                                        <div className="slot-item__time">{time}</div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            {elem.specific_questions ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">Specific question </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.specific_questions}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name">
                                                    Where should suppliers report to upon arrival at the school?{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.arrival_point}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.arrival_point) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={Pitch} />
                                                {elem.name}{' '}
                                                <Tooltip
                                                    title={
                                                        <div style={{ fontWeight: '400' }}>
                                                            Shortlisted suppliers will advance to the next evaluation
                                                            gate, where they will present to your adjudication
                                                            committee. <br />
                                                            <span style={{ fontWeight: '600' }}>Instruction</span>: Fill
                                                            out this form to let us know you availability for hosting
                                                            your shortlisted suppliers for this evaluation gate.
                                                        </div>
                                                    }
                                                >
                                                    <img src={igrey} className="task-complete__igrey" />
                                                </Tooltip>
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task__open" onClick={() => setIsPitchModal(true)}>
                                            Open
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'pending_su' || elem.status === 'need_resend_tr') &&
                                elem.arrival_point
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            elem.status === 'need_resend_tr' ? '' : 'green'
                                        }`}
                                    >
                                        {' '}
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={Pitch} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                {elem.status === 'pending_su' && (
                                                    <div className="task-complete__edit-pitch">
                                                        <img
                                                            src={edit}
                                                            onClick={() => setIsPitchModal(true)}
                                                            className="task-complete__edit-pitch-image"
                                                        />
                                                    </div>
                                                )}
                                                {elem.status === 'need_resend_tr' && (
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            {elem.comments?.tender_representative && (
                                                <div className="note" style={{ margin: '10px 0' }}>
                                                    <Message />
                                                    {elem.comments?.tender_representative}
                                                </div>
                                            )}

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '170px', margin: '0 0 10px 0' }}
                                                >
                                                    Free slot for Campus Tour
                                                </div>
                                            </div>
                                            <div className="slot-item">
                                                <div className="slot-item__week">
                                                    {moment(elem.campus_tour.date).format('ddd')}
                                                </div>
                                                <div className="slot-item__date">
                                                    {moment(elem.campus_tour.date).format('DD MMMM YYYY')}
                                                </div>
                                                <div className="slot-item__time">
                                                    {elem.campus_tour.time_from}-{elem.campus_tour.time_to}
                                                </div>
                                            </div>
                                            <Divider style={{ margin: '16px 0' }} />
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '125px' }}>
                                                    Duration
                                                </div>
                                                <div className="task-complete__name">Representatives</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ width: '125px' }}>
                                                    <ClockIcon fill="#001965" />
                                                    {elem.duration}min
                                                </div>
                                                <div className="task-complete__value">
                                                    <PeopleIcon fill="#001965" style={{ margin: '0 5px 0 0' }} />
                                                    {elem.representatives_count}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '200px', margin: '0 0 10px 0' }}
                                                >
                                                    Free slots for supplier pitches
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__slot-list">
                                                    {slots?.map((elem: GroupedData) => {
                                                        return (
                                                            <div className="slot-item">
                                                                <div className="slot-item__week">{elem.dayOfWeek}</div>
                                                                <div className="slot-item__date">{elem.date}</div>
                                                                {elem.time.map((time: string) => {
                                                                    return (
                                                                        <div className="slot-item__time">{time}</div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            {elem.specific_questions ? (
                                                <>
                                                    <div
                                                        className="task-complete__wrap"
                                                        style={{ margin: '10px 0 0 0' }}
                                                    >
                                                        <div className="task-complete__name">Specific question </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.specific_questions}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name">
                                                    Where should suppliers report to upon arrival at the school?{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.arrival_point}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <>
                    {role === 'school_user' && (
                        <div className="noTasks">
                            <img src={LetterIcon} />
                            <div className="noTasks__title">Welcome to Evaluation Gate 1+2!</div>
                            <div className="noTasks__subtitle">
                                Watch this space - new tasks for you will be posted here as they become available.
                            </div>
                        </div>
                    )}
                </>
            )}
            {isPitchModal && (
                <PitchForm
                    openModal={isPitchModal}
                    setOpenModal={setIsPitchModal}
                    TenderData={data}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    supplirsLength={data?.suppliers.filter((item) => item.status === 'shortlisted')?.length}
                />
            )}
            {openResendTask && (
                <ResendFormModal
                    openModal={openResendTask}
                    setOpenModal={setOpenResendTask}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
        </>
    );
};
