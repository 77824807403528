import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useSupplyAgreementMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface FormDatatoSend {
    ids: { tender_id: number; supplier_id: string };
    data: { link: string; resend?: boolean };
}

interface SupplierData {
    company: { name: string };
    manager: { name: string; email: string; phone_number: string };
    name: string;
    email: string;
    incumbent: boolean;
    id: string;
    name_prefix: string;
    link: string;
    payload: {
        supply_agreement: string;
    };
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    data?: tenderDataProps;
    SupplierData: SupplierData;
    isResend?: boolean;
}

const validationSchema = Yup.object().shape({
    link: Yup.string()
        .required('This is a required field')
        .matches(/^(https?:\/\/)/, 'Oops! Your link is not correct')
});

export const AgreementLinkModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    data,
    setTrigger,
    SupplierData,
    isResend = false
}) => {
    const [isDirty, setIsDirty] = useState(false);

    console.log('isResend', isResend);

    const [
        supplyAgreement,
        { data: linkData, isLoading: isLoadingLinkData, isSuccess: isSuccessLinkData, isError: isErrorLinkData }
    ] = useSupplyAgreementMutation();

    const { handleKeyPress } = usePreventSpaceTyping();

    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);

    useEffect(() => {
        if (isSuccessLinkData) {
            messageApi?.success(linkData?.message);
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
        if (isErrorLinkData) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessLinkData, isErrorLinkData]);

    useEffect(() => {
        if (SupplierData?.payload?.supply_agreement) {
            form.setFieldsValue({
                link: SupplierData.payload?.supply_agreement
            });
        }
    }, [SupplierData]);

    const handleFormSubmit = () => {
        if (isLoadingLinkData) return;
        form.validateFields()
            .then((values: any) => {
                if (!data?.id) return;
                const formData: FormDatatoSend = {
                    ids: {
                        tender_id: data?.id,
                        supplier_id: SupplierData?.id
                    },
                    data: { link: values.link, resend: isResend }
                };
                supplyAgreement(formData);
            })
            .catch(() => {});
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>

                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoadingLinkData ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">
                            ({SupplierData.name_prefix}) {SupplierData.name}
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <Form form={form} onFinish={handleFormSubmit} onChange={() => setIsDirty(true)}>
                            <Form.Item name="link" rules={yupSync('link', validationSchema, true)}>
                                <FloatTextArea
                                    floatLabel={'Link to Supply Agreement'}
                                    maxLength={1024}
                                    required={false}
                                    onKeyPress={handleKeyPress}
                                    hasError={() => !!form.getFieldError(['link']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
