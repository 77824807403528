import { Divider } from 'antd';
import dots from '../../assets/dots.svg';
import School from '../../assets/schoolicon.svg';
import MoneyIcon from '../../assets/MoneyIcon.svg';
import idicon from '../../assets/idicon.svg';
import onTrack from '../../assets/onTrack.svg';
import messageIcon from '../../assets/chat-message.svg';
import Tasklist from '../../assets/Task list, Document, Text.svg';
import clock from '../../assets/clock-time-cross.svg';
import onhold from '../../assets/onhold.svg';
import flag from '../../assets/Flag, Circle.svg';
import { PhaseI } from '../../api/types/common';
import CancelBtn from '../../assets/CancelBtn.svg';
import timefast from '../../assets/time-fast.svg';
import Reminder1 from '../../assets/Reminder1.svg';
import Reminder2 from '../../assets/Reminder2.svg';
import Reminder3 from '../../assets/Reminder3.svg';

import { FC, useEffect, useRef, useState } from 'react';
import edit from '../../assets/editAdminIcon.svg';
import { useAppSelector } from '../../hooks';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TimelineModal } from '../../components/Modals/TimelineModal';

interface Props {
    item: PhaseI;
    setIsOpenEditTenderModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectTender: React.Dispatch<React.SetStateAction<number | null>>;
    setOpenCancelTenderModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedTender: React.Dispatch<React.SetStateAction<PhaseI | null>>;
    setOpenManage: React.Dispatch<React.SetStateAction<number | null>>;
    openManage: number | null;
    setTrigger: any;
    messageApi: any;
}

export const KanbanItem: FC<Props> = ({
    item,
    setIsOpenEditTenderModal,
    setSelectTender,
    setOpenCancelTenderModal,
    setSelectedTender,
    setOpenManage,
    openManage,
    messageApi
}) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (!openManage) {
            setSelectTender(null);
        }
    }, [openManage]);

    useClickOutside(ref, () => setOpenManage(null));

    let reminder = item.reminders;

    return (
        <>
            <div className="kanban__card card" onClick={() => navigate(`/tender/${item.id}`)}>
                <div className="card__row top" ref={ref}>
                    <div className="card__title">{item.expense_area_name} tender</div>
                    <div
                        className="card__menu"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenManage(item.id);
                        }}
                    >
                        <img src={dots} />
                    </div>
                    {openManage === item.id && (
                        <div className="head__openManage openManage">
                            {role === 'super_admin' && (
                                <div
                                    className="openManage__item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpenEditTenderModal(true);
                                        setSelectTender(item.id);
                                    }}
                                >
                                    <img src={edit} />
                                    Edit Tender
                                </div>
                            )}
                            <div
                                className="openManage__item cancel-tender"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedTender(item);
                                    setOpenCancelTenderModal(true);
                                }}
                            >
                                <img src={CancelBtn} />
                                Cancel Tender
                            </div>
                        </div>
                    )}
                </div>
                <div className="card__main main">
                    <div className="main__row">
                        <img src={School} />
                        <div className="main__name">{item.school_name}</div>
                    </div>
                    <div className="main__row">
                        <img src={MoneyIcon} />
                        <div className="main__name">
                            R {item.contract_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                    </div>
                    <div className="main__row">
                        <img src={idicon} />
                        <div className="main__name">{item.number}</div>
                    </div>
                </div>
                <div className="card__row">
                    {item.phase_task_status === 'on_track' && !reminder && (
                        <div className="card__status on-track">
                            <img src={onTrack} />
                            On-track
                        </div>
                    )}
                    {item.phase_task_status === 'late' && !reminder && (
                        <div className="card__status late">
                            <img src={clock} />
                            Late
                        </div>
                    )}
                    {item.phase_task_status === 'follow_up' && !reminder && (
                        <div className="card__status follow_up">
                            <img src={flag} />
                            Follow-up
                        </div>
                    )}
                    {item.phase_task_status === 'on_hold' && (
                        <div className="card__status">
                            <img src={onhold} />
                        </div>
                    )}
                    {item.phase_task_status !== 'on_hold' && reminder && (
                        <div className="card__reminders">
                            <img src={flag} />
                            {reminder === 1 && '1st'}
                            {reminder === 2 && '2nd'}
                            {reminder === 3 && '3rd'} Reminder
                            {reminder === 1 && <img src={Reminder1} />}
                            {reminder === 2 && <img src={Reminder2} />}
                            {reminder === 3 && <img src={Reminder3} />}
                        </div>
                    )}

                    <div className="card__row">
                        <img src={messageIcon} className="card__message-icon" />
                        <div className={`card__number ${item?.messages_count > 0 ? 'blue' : 'grey'}`}>
                            {item?.messages_count}
                        </div>
                    </div>
                </div>
                <Divider style={{ width: '112%', marginLeft: '-16px' }} />
                <div className="card__row">
                    <div className={`card__task`}>
                        <img src={Tasklist} />
                        Task{' '}
                        <div className={`card__number ${item?.tasks_count > 0 ? 'red' : 'grey'}`}>
                            {item.tasks_count}
                        </div>
                    </div>
                    {item.deadline && (
                        <div className={`card__deadline`}>Deadline {moment(item.deadline).format('YYYY-MM-DD')}</div>
                    )}
                </div>
                {item.phase_task_status === 'on_hold' && (
                    <>
                        <Divider style={{ width: '112%', margin: '10px 0 10px -16px' }} />
                        <div
                            className="card__change-timeline"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenModal(true);
                            }}
                        >
                            <img src={timefast} />
                            Change Timeline
                        </div>
                    </>
                )}
            </div>
            {openModal && (
                <TimelineModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setTrigger={() => {}}
                    isCreateModal={false}
                    tenderId={item.id}
                    messageApi={messageApi}
                />
            )}
        </>
    );
};
