import { FC, useState } from 'react';
import { message } from 'antd';
import onTrack from '../../assets/onTrack.svg';
import clock from '../../assets/clock-time-cross.svg';
import editIcon from '../../assets/editAdminIcon.svg';
import calendar from '../../assets/Calendar, Schedule, Clock.svg';
import i from '../../assets/igrey.svg';
import tenderDesign from '../../assets/tenderDesign.svg';
import tenderDefine from '../../assets/tenderDefine.svg';
import tenderLaunch from '../../assets/tenderLaunch.svg';
import Evaluate1 from '../../assets/Evaluate1+2.svg';
import AwardIcon from '../../assets/AwardIcon.svg';
import SupplierAppointment from '../../assets/SupplierAppointment.svg';
import arrow from '../../assets/arrow.svg';
import moment from 'moment';
import { useAppSelector } from '../../hooks';
import { TimelineModal } from '../../components/Modals/TimelineModal';
import { useNavigate } from 'react-router-dom';
import Edit from '../../components/Icons/Edit';
import { SUTimelineOngoing } from './SUTimelineOngoing';
import { PhaseExplanation } from './PhaseExplanation';
import './index.scss';

interface Props {
    item: any;
    tab: string;
    setTrigger: any;
}

export const KanbanItemSU: FC<Props> = ({ item, tab, setTrigger }) => {
    const user = useAppSelector((state) => state.profileReducer);
    const [openModal, setOpenModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isOpenTender, setIsOpenTender] = useState(false);
    const navigate = useNavigate();
    const [openExplanationModal, setOpenExplanationModal] = useState<string>('');

    const activePhase = item.phase;
    const indexActivePhase = item.timeline.findIndex((elem: any) => elem.key === activePhase);

    return (
        <>
            {contextHolder}
            {tab === 'ongoing' ? (
                <div className={`su ${isOpenTender ? 'opened' : ''}`}>
                    <div
                        className={`su__top-row ${isOpenTender ? '' : 'closed'} ${
                            user.id === item.su_id ? '' : 'not-active'
                        }`}
                        onClick={() => {
                            if (user.id === item.su_id) {
                                navigate(`/tender/${item.id}`);
                            }
                        }}
                    >
                        <div className="su__item left">
                            <div className="su__title">
                                <img
                                    src={arrow}
                                    className={`su__open_arrow ${isOpenTender ? 'opened' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpenTender((prev) => !prev);
                                    }}
                                />
                                <span> {item.expense_area_name} tender</span>
                            </div>
                            <div className="card__row">
                                {item.phase_task_status === 'on_track' && (
                                    <div className="su__status on-track">
                                        <img src={onTrack} />
                                        On-track
                                    </div>
                                )}
                                {(item.phase_task_status === 'late' ||
                                    item.phase_task_status === 'follow_up' ||
                                    item.phase_task_status === 'on_hold') && (
                                    <div className="su__status late">
                                        <img src={clock} />
                                        Late
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="su__item info">
                            <div className="su__info-item">
                                <div className="su__name">ID</div>
                                <div className="su__value">{item.number}</div>
                            </div>
                            <div className="su__info-item">
                                <div className="su__name">Messenger</div>

                                <div className={`su__number ${item?.messages_count > 0 ? 'red' : 'grey'}`}>
                                    {item.messages_count}
                                </div>
                            </div>
                            <div className="su__info-item">
                                <div className="su__name">Tasks</div>

                                <div className={`su__number ${item?.tasks_count > 0 ? 'red' : 'grey'}`}>
                                    {item.tasks_count}
                                </div>
                            </div>
                            <div className="su__info-item">
                                <div className="su__name">Your Next Deadline</div>
                                <div className="su__value">
                                    {item.deadline ? (
                                        <>
                                            <img src={calendar} />
                                            {moment(item.deadline).format('DD MMM YYYY')}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`su__bottom-row ongoing ${isOpenTender ? '' : 'closed'}`}>
                        {item.timeline?.map((elem: any, index: number) => {
                            return (
                                <>
                                    <SUTimelineOngoing
                                        indexActivePhase={indexActivePhase}
                                        index={index}
                                        elem={elem}
                                        activePhase={activePhase}
                                        item={item}
                                        setOpenExplanationModal={setOpenExplanationModal}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div className={`su  ${isOpenTender ? 'opened' : ''}`}>
                    <div className={`su__top-row upcoming ${isOpenTender ? '' : 'closed'} `}>
                        <div className="su__item left">
                            <div className="su__title upgoing">
                                {' '}
                                <img
                                    src={arrow}
                                    className={`su__open_arrow ${isOpenTender ? 'opened' : ''}`}
                                    onClick={() => setIsOpenTender((prev) => !prev)}
                                />{' '}
                                {item.expense_area_name} tender
                            </div>
                        </div>
                        <div className="su__item info">
                            <div className="su__info-item">
                                <div className="su__name">ID</div>
                                <div className="su__value">{item.number}</div>
                            </div>
                            <div className="su__info-item" style={{ border: 'none' }}>
                                <div className="su__name">Starts at</div>
                                <div className="su__value">
                                    <img src={calendar} />
                                    {moment(item.start_date).format('DD MMM YYYY')}
                                </div>
                            </div>

                            <div className="su__edit" onClick={() => setOpenModal(true)}>
                                <img src={editIcon} />
                                <div>Edit</div>
                            </div>
                        </div>
                    </div>
                    <div className={`su__bottom-row ${isOpenTender ? '' : 'closed'}`}>
                        {item.timeline?.map((elem: any, index: number) => {
                            return (
                                <>
                                    <div className="su__phase phase">
                                        <div className="phase__title">
                                            {elem.name}{' '}
                                            {elem.name !== 'Supp. Appointment' && (
                                                <img src={i} onClick={() => setOpenExplanationModal(elem.name)} />
                                            )}
                                        </div>

                                        <div className="phase__img">
                                            {elem.key === 'define' && <img src={tenderDefine} />}
                                            {elem.key === 'design' && <img src={tenderDesign} />}
                                            {elem.key === 'launch' && <img src={tenderLaunch} />}
                                            {elem.key === 'evaluate_1_2' && <img src={Evaluate1} />}
                                            {elem.key === 'evaluate_3' && <img src={Evaluate1} />}
                                            {elem.key === 'evaluate_4' && <img src={Evaluate1} />}
                                            {elem.key === 'award' && <img src={AwardIcon} />}
                                            {elem.key === 'supplier_appointment' && <img src={SupplierAppointment} />}
                                        </div>

                                        <div className="phase__date">
                                            {elem.key === 'define' && <div className="su__name">Start Date</div>}
                                            {elem.key === 'supplier_appointment' && (
                                                <div className="su__name">Estimated Completion</div>
                                            )}
                                            {elem.key !== 'supplier_appointment' && elem.key !== 'define' && (
                                                <div className="su__name">Estimated Start</div>
                                            )}
                                            {elem.key === 'define' && (
                                                <div className="su__value">
                                                    {moment(elem?.estimated_start).format('DD MMM YYYY')}
                                                </div>
                                            )}
                                            {elem.key === 'supplier_appointment' && (
                                                <div className="su__value">
                                                    {moment(elem?.estimated_end).format('DD MMM YYYY')}
                                                    <div
                                                        onClick={() => setOpenModal(true)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <Edit fill="grey" />
                                                    </div>
                                                </div>
                                            )}
                                            {elem.key !== 'supplier_appointment' && elem.key !== 'define' && (
                                                <div className="su__value">
                                                    {moment(elem?.estimated_start).format('DD MMM YYYY')}
                                                </div>
                                            )}
                                        </div>
                                    </div>{' '}
                                    {index < item.timeline?.length - 1 && <div className="phase__line"></div>}
                                </>
                            );
                        })}
                    </div>

                    {openModal && (
                        <TimelineModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            setTrigger={setTrigger}
                            isCreateModal={false}
                            tenderId={item.id}
                            messageApi={messageApi}
                        />
                    )}
                </div>
            )}
            {!!openExplanationModal && (
                <PhaseExplanation open={openExplanationModal} setOpenModal={setOpenExplanationModal} />
            )}
        </>
    );
};
