import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { uploadDocuments, createAbortController } from '../../api/axiosCruds';
import close from '../../assets/RoundedClose.svg';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data?: tenderDataProps;
    taskId?: number | null | string;
    supplierData: any;
    messageApi: MessageApi;
}

interface SuppDataI {
    index: number;
    id: number;
    file: any;
    error: string;
    name: string;
}

interface FormDataI {
    comment: string;
}

export const UploadCompanyDocumentationModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    data,
    setTrigger,
    taskId,
    supplierData,
    messageApi
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm<FormDataI>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [file, setFile] = useState<any>({
        financial_accounts: {
            error: '',
            file: []
        },
        bbee: {
            error: '',
            file: []
        },
        sars: {
            error: '',
            file: []
        },
        registration: {
            error: '',
            file: []
        }
    });
    const [suppliersArray, setSuppliersArray] = useState<SuppDataI[]>();
    const [isDirty, setIsDirty] = useState(false);

    console.log('data', data);
    console.log('supplierData', supplierData);
    console.log('file', file);

    useEffect(() => {
        if (supplierData) {
            if (supplierData?.documents.length) {
                const filesArray = {
                    financial_accounts: {
                        error: '',
                        file: supplierData?.documents?.[0]?.file_name
                            ? [{ name: supplierData?.documents?.[0]?.file_name }]
                            : []
                    },
                    bbee: {
                        error: '',
                        file: supplierData?.documents?.[1]?.file_name
                            ? [{ name: supplierData?.documents?.[1]?.file_name }]
                            : []
                    },
                    sars: {
                        error: '',
                        file: supplierData?.documents?.[2]?.file_name
                            ? [{ name: supplierData?.documents?.[2]?.file_name }]
                            : []
                    },
                    registration: {
                        error: '',
                        file: supplierData?.documents?.[3]?.file_name
                            ? [{ name: supplierData?.documents?.[3]?.file_name }]
                            : []
                    }
                };
                setFile(filesArray);
                if (supplierData?.comment) {
                    form.setFieldValue('comment', supplierData?.comment);
                }
            }
        }
    }, [supplierData]);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    interface postData {
        ids: { tender_id: number | undefined; task_id: number | undefined | string | null };
        data: FormData;
    }

    const updateTaskFunc = async (prop: { comment: string }) => {
        setIsLoading(true);
        const abortController = createAbortController();
        const fileData = new FormData();
        const comment: string = prop.comment ? prop.comment : '';
        const method: string = 'PUT';
        const document1: any = file.financial_accounts.file?.[0]?.originFileObj;
        const document2: any = file.bbee.file?.[0]?.originFileObj;
        const document3: any = file.sars.file?.[0]?.originFileObj;
        const document4: any = file.registration.file?.[0]?.originFileObj;
        fileData.append('comment', comment);
        fileData.append('_method', method);
        fileData.append('supplier_id', supplierData?.id);
        fileData.append('documents[0][key]', '0');
        if (document1) {
            fileData.append('documents[0][file]', document1);
        }
        fileData.append('documents[1][key]', '1');
        if (document2) {
            fileData.append('documents[1][file]', document2);
        }
        fileData.append('documents[2][key]', '2');
        if (document3) {
            fileData.append('documents[2][file]', document3);
        }
        fileData.append('documents[3][key]', '3');
        if (document4) {
            fileData.append('documents[3][file]', document4);
        }

        const copy = suppliersArray ? [...suppliersArray] : [];
        suppliersArray?.forEach((elem: SuppDataI, index: number) => {
            const id: string = elem.id + '';

            if (elem.file && (!elem.error || elem.error === 'Upload failed. Check your internet connection')) {
                const contentFile: any = elem?.file?.[0]?.originFileObj;

                if (contentFile) {
                    const file: File = contentFile;
                    const fileName: string = elem.file.name;
                    fileData.append(`suppliers[${index}][file]`, file, fileName);
                }

                fileData.append(`suppliers[${index}][id]`, id);
            }

            if (abortController) {
                abortController.abort();
            }
            copy[index].error = '';
        });

        setSuppliersArray(copy);
        const datatoPost: postData = {
            ids: { tender_id: data?.id, task_id: taskId },
            data: fileData
        };
        const newAbortController = createAbortController();
        setAbortController(newAbortController);
        const signal = newAbortController.signal;
        try {
            await uploadDocuments(datatoPost, signal);
            await setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            await messageApi?.success('The task has been updated successfully');
        } catch {
            // setFileErr('Upload failed. Check your internet connection');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = () => {
        let isValid = true;
        if (
            !file.financial_accounts.file.length ||
            !file.bbee.file.length ||
            !file.sars.file.length ||
            !file.registration.file.length
        ) {
            isValid = false;
        }

        setFile((prev: any) => {
            const updatedFile = { ...prev };

            Object.keys(updatedFile).forEach((key) => {
                const category = key as keyof typeof updatedFile;
                updatedFile[category].error = updatedFile[category].file.length
                    ? ''
                    : 'You can upload files in the following formats: doc, pdf with a maximum size of 100MB';
            });

            return updatedFile;
        });

        if (isValid) {
            form.validateFields()
                .then((values: any) => {
                    if (isLoading) return;
                    updateTaskFunc(values);
                })
                .catch(() => {});
        }
    };

    return (
        <>
            <Modal
                className="modal-modal upload-documents-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={<></>}
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Company Documentation</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 15px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            Upload company documents for <b style={{ color: '#001965' }}>{supplierData?.name}</b>
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <div>
                                <div className="main__name">Financial Accounts</div>
                                <Form.Item name="file">
                                    <FilePicker
                                        disabled={isLoading ? true : false}
                                        fileList={file.financial_accounts.file}
                                        onChange={(value: any) => {
                                            setFile((prev: any) => ({
                                                ...prev,
                                                financial_accounts: {
                                                    error: '',
                                                    file: value
                                                }
                                            }));
                                            setIsDirty(true);
                                        }}
                                        error={file.financial_accounts.error}
                                        setError={(error: any) => {}}
                                        specialFormats={['pdf', 'doc']}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div className="main__name">B-BBEE Affidavit / Certificate</div>
                                <Form.Item name="file">
                                    <FilePicker
                                        disabled={isLoading ? true : false}
                                        fileList={file.bbee.file}
                                        onChange={(value: any) => {
                                            setFile((prev: any) => ({
                                                ...prev,
                                                bbee: {
                                                    error: '',
                                                    file: value
                                                }
                                            }));
                                            setIsDirty(true);
                                        }}
                                        error={file.bbee.error}
                                        setError={(error: any) => {}}
                                        specialFormats={['pdf', 'doc']}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div className="main__name">SARS Letter of Good Standing</div>
                                <Form.Item name="file">
                                    <FilePicker
                                        disabled={isLoading ? true : false}
                                        fileList={file.sars.file}
                                        onChange={(value: any) => {
                                            setFile((prev: any) => ({
                                                ...prev,
                                                sars: {
                                                    error: '',
                                                    file: value
                                                }
                                            }));
                                            setIsDirty(true);
                                        }}
                                        error={file.sars.error}
                                        setError={(error: any) => {}}
                                        specialFormats={['pdf', 'doc']}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div className="main__name">Company Registration Certificate</div>
                                <Form.Item name="file">
                                    <FilePicker
                                        disabled={isLoading ? true : false}
                                        fileList={file.registration.file}
                                        onChange={(value: any) => {
                                            setFile((prev: any) => ({
                                                ...prev,
                                                registration: {
                                                    error: '',
                                                    file: value
                                                }
                                            }));
                                            setIsDirty(true);
                                        }}
                                        error={file.registration.error}
                                        setError={(error: any) => {}}
                                        specialFormats={['pdf', 'doc']}
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item name="comment">
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment'])?.length}
                                    onInput={() => setIsDirty(true)}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className="upload-documents-modal__footer">
                    <Button
                        block
                        key="back"
                        onClick={() => {
                            if (isDirty) {
                                setIsConfirmModal(true);
                            } else {
                                setOpenModal(false);
                            }
                        }}
                        className="cancel upload-documents-modal__btn"
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        key="submit"
                        type="primary"
                        onClick={handleFormSubmit}
                        className={'upload-documents-modal__btn'}
                    >
                        {isLoading ? <Loader /> : 'Add'}
                    </Button>
                </div>
            </Modal>

            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
