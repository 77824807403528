import { Button } from 'antd';
import logo from '../../assets/logoForHeader.png';
import hands from '../../assets/Hands.svg';
import './index.scss';
import { useSearchParams } from 'react-router-dom';

export const SupplierFeedback = () => {
    const [searchParams] = useSearchParams();
    const name = searchParams.get('supplier');

    return (
        <div className="feedback">
            <div className="feedback__logowrap">
                <img src={logo} className="feedback__logo" />
            </div>

            <div className="feedback__main">
                <img src={hands} className="feedback__hands" />
                <div className="feedback__title">Thank You for Your Feedback!</div>
                <div className="feedback__subtitle">
                    Your rating for <b>{name}</b> has been recorded.
                </div>
                <div className="feedback__subtitle" style={{ margin: '16px 0 0 0' }}>
                    Your feedback is essential in helping schools make informed decisions when selecting suppliers.
                </div>
                <div className="feedback__addtext">
                    Click below to learn more about SchoolAdvisor and how we help school run tenders
                </div>
                <Button
                    className="fields__btn feedback__btn"
                    type="primary"
                    onClick={() => (window.location.href = 'https://schooladvisor.co.za')}
                >
                    Learn More
                </Button>
            </div>
            <div className="feedback__footer">
                Your feedback will be securely stored and made available to other schools for a 12-month period to
                support their evaluation process.
            </div>
        </div>
    );
};
