import { Modal } from 'antd';
import close from '../../assets/RoundedClose.svg';
import tenderDesign from '../../assets/tenderDesign.svg';
import tenderDefine from '../../assets/tenderDefine.svg';
import tenderLaunch from '../../assets/tenderLaunch.svg';
import Evaluate1 from '../../assets/Evaluate1+2.svg';
import AwardIcon from '../../assets/AwardIcon.svg';
import KickoffIcon from '../../assets/KickoffIcon.svg';
import SiteVisit from '../../assets/SiteVisit.svg';
import defineMeeting from '../../assets/define-meeting.svg';
import TenderMandat from '../../assets/TenderMandat.svg';
import SuppDocIcon from '../../assets/SuppDocIcon.svg';
import ReviewIcon from '../../assets/ReviewIcon.svg';
import StayIcon from '../../assets/StayIcon.svg';
import CampusTourIcon from '../../assets/CampusTourIcon.svg';
import WatchIcon from '../../assets/WatchIcon.svg';
import ConfirmTheDateIcon from '../../assets/ConfirmTheDateIcon.svg';
import AttendIcon from '../../assets/AttendIcon.svg';
import SelectIcon from '../../assets/SelectIcon.svg';
import CompleteIcon from '../../assets/CompleteIcon.svg';
import SignIcon from '../../assets/SignIcon.svg';

const Task = (image: any, name: string, text: string) => {
    return (
        <div className="explanation-task">
            <div className="explanation-task__row">
                <img src={image} className="explanation-task__image" />

                <div className="explanation-task__name">{name}</div>
            </div>
            {text && <div className="explanation-task__text">{text}</div>}
        </div>
    );
};

export const PhaseExplanation = ({ open, setOpenModal }: any) => {
    console.log('open', open);
    return (
        <>
            <Modal
                className="explanation-modal"
                destroyOnClose
                centered
                open={open}
                onCancel={() => setOpenModal('')}
                closeIcon={<img src={close} />}
                footer={<></>}
            >
                <>
                    <div className="explanation-modal__main">
                        <div>
                            <img
                                src={
                                    open === 'Define'
                                        ? tenderDefine
                                        : open === 'Design'
                                        ? tenderDesign
                                        : open === 'Launch'
                                        ? tenderLaunch
                                        : open === 'Evaluation Gate 1+2' ||
                                          open === 'Evaluation Gate 3' ||
                                          open === 'Evaluation Gate 4'
                                        ? Evaluate1
                                        : open === 'Award'
                                        ? AwardIcon
                                        : ''
                                }
                                className="explanation-modal__image"
                            />
                        </div>
                        <div className="explanation-modal__title">
                            {open === 'Define'
                                ? 'Define Phase'
                                : open === 'Design'
                                ? 'Define Phase'
                                : open === 'Launch'
                                ? 'Launch Phase'
                                : open === 'Evaluation Gate 1+2'
                                ? 'Evaluation Gate 1 & 2'
                                : open === 'Evaluation Gate 3'
                                ? 'Evaluation Gate 3'
                                : open === 'Evaluation Gate 4'
                                ? 'Evaluation Gate 4'
                                : open === 'Award'
                                ? 'Award Phase'
                                : ''}
                        </div>
                        <div className="explanation-modal__subtitle">
                            {open === 'Define' ? (
                                'During this phase, we focus on understanding your requirements and defining what good looks like from both a solution and supplier perspective'
                            ) : open === 'Design' ? (
                                'During this phase, you will review and approve our understanding of your requirements before the tender is launched'
                            ) : open === 'Launch' ? (
                                <>
                                    In this phase, we onboard any remaining suppliers and then go live with the tender.
                                    <br />
                                    No action is required from your side, but we may reach out if suppliers submit
                                    questions that need your input. Keep an eye on the chat for any queries that may
                                    come through.
                                </>
                            ) : open === 'Evaluation Gate 1+2' ? (
                                'In this phase, the evaluation process begins. We will present which suppliers have passed Evaluation Gate 1 and share the responses assessed by our AI evaluation tool for the Ideal Supplier Questionnaire (Evaluation Gate 2)'
                            ) : open === 'Evaluation Gate 3' ? (
                                'In this phase, suppliers will present their company and proposed solution in a pitch session. Each supplier will be scored based on their presentation and offering. We will analyse these scores and provide insights to support the shortlisting process'
                            ) : open === 'Evaluation Gate 4' ? (
                                'In this phase, suppliers will have the opportunity to resubmit their pricing on our platform after receiving feedback on their relative position. We will consolidate the revised prices and present them to you for final review'
                            ) : open === 'Award' ? (
                                'In this phase, the agreement between you and your appointed supplier is finalised'
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="explanation-modal__what">What You’ll Be Doing:</div>
                        <div className="explanation-modal__tasks">
                            {open === 'Define' && (
                                <>
                                    {Task(
                                        KickoffIcon,
                                        'Kick-off Questionnaire',
                                        'Share insights about your current setup and future needs by completing a short questionnaire.'
                                    )}
                                    {Task(
                                        SiteVisit,
                                        'Site Visit (if applicable)',
                                        'A representative will visit your site to gather detailed insights about your setup.'
                                    )}
                                    {Task(
                                        defineMeeting,
                                        'Define Phase Meeting',
                                        'Meet your dedicated tender representative to address follow-up questions and gain a clear understanding of our tender process.'
                                    )}
                                    {Task(
                                        TenderMandat,
                                        'Tender Mandate',
                                        'Confirm our appointment to support your tender process by finalising the tender mandate.'
                                    )}
                                    {Task(
                                        SuppDocIcon,
                                        'Supporting Documents',
                                        'Provide key materials such as invoices, agreements, and other relevant documents to help us fully understand your needs.'
                                    )}
                                </>
                            )}
                            {open === 'Design' && (
                                <>
                                    {' '}
                                    {Task(
                                        ReviewIcon,
                                        'Tender Fact Pack',
                                        'Review the summary of the tender scope, bid requirements, and how bids will be evaluated.'
                                    )}
                                    {Task(
                                        ReviewIcon,
                                        'Supply Agreement',
                                        'Review the terms and conditions that will govern the relationship between you and the appointed supplier (if requested)'
                                    )}
                                    {Task(
                                        ReviewIcon,
                                        'Specification',
                                        'Review the detailed scope of services, including required tasks, standards, and service frequencies (If applicable)'
                                    )}
                                </>
                            )}
                            {open === 'Launch' && (
                                <>
                                    {Task(
                                        WatchIcon,
                                        'Supplier Questions',
                                        'We may contact you via chat if supplier questions or clarifications arise that require your input. '
                                    )}
                                </>
                            )}
                            {open === 'Evaluation Gate 1+2' && (
                                <>
                                    {Task(
                                        SuppDocIcon,
                                        'Shortlist Suppliers',
                                        'You and your adjudication committee will review the results of Evaluation Gate 2 and decide which suppliers to shortlist. '
                                    )}
                                    {Task(ConfirmTheDateIcon, 'Confirm Campus Tour and Supplier Pitches Dates', '')}
                                </>
                            )}
                            {open === 'Evaluation Gate 3' && (
                                <>
                                    {Task(
                                        CampusTourIcon,
                                        'Campus Tour',
                                        'Shortlisted suppliers will tour your campus with a guided explanation to ensure they fully understand the requirements.'
                                    )}
                                    {Task(
                                        AttendIcon,
                                        'Supplier Pitches ',
                                        'You and your adjudication committee will assess supplier pitches and evaluate their solutions. '
                                    )}
                                    {Task(
                                        SuppDocIcon,
                                        'Shortlist Suppliers',
                                        'You and your adjudication committee will review the results of Evaluation Gate 3 and decide which suppliers to shortlist.'
                                    )}
                                </>
                            )}
                            {open === 'Evaluation Gate 4' && (
                                <>
                                    {Task(SelectIcon, 'Select Preferred Supplier', '')}
                                    {Task(
                                        CompleteIcon,
                                        'Letter of Intent',
                                        'Complete the Letter of Intent template to formalise your decision.'
                                    )}
                                </>
                            )}
                            {open === 'Award' && (
                                <>
                                    {Task(
                                        SignIcon,
                                        'Sign Supply Agreement ',
                                        'Complete the tender process by signing the agreement with your appointed supplier. '
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            </Modal>
        </>
    );
};
